import Heading from "../../../components/Heading";
import Container from "../../../components/Container";
import P from "../../../components/P";

const SobreSalicornia = () => {
  return (
    <Container width="100%" position="relative" id="sobre-salicornia">
      <Container
        className="sobre-background"
        backgroundImage="/fundos_pintas/pintas_brancas.svg"
        backgroundRepeat="repeat"
        backgroundSize="auto"
        position="absolute"
        top="0"
        right="0"
        bottom="0"
        left="0"
      />
      <Container maxWidth="1000px" margin="0 auto" position="relative">
        <Container
          backgroundColor="#164427"
          width="80%"
          tabletWidth="50%"
          laptopWidth="35%"
          position="absolute"
          top="-20px"
          left="calc(50% - 80% / 2)"
          tabletLeft="calc(50% - 50% / 2)"
          laptopLeft="calc(50% - 35% / 2)"
          padding="0.5rem 0"
          flex
          justifyContent="center"
        >
          <Heading
            fontWeight="bolder"
            letterSpacing="1.5px"
            level="2"
            laptopLevel="2.8"
            textAlign="center"
            textColor="white"
            fontFamily="secondary"
          >
            ~ SALGA NATURALMENTE ~
          </Heading>
        </Container>
        <Container
          padding="3rem"
          laptopPadding="6rem 0"
          laptopLPadding="6rem 0"
        >
          <Container>
            <Container>
              <Heading
                level="1"
                laptopLevel="0.8"
                textColor="white"
                fontWeight="bold"
              >
                Sobre
              </Heading>
              <Heading
                level="1"
                laptopLevel="0.8"
                fontWeight="bold"
                textColor="#164427"
              >
                Salicórnia
              </Heading>
            </Container>
            <Container laptopWidth="88%" width="100%" margin="4% 0 0 0">
              <Container>
                <P fontSize="15px" laptopFontSize="18px" color="white">
                  A Salicórnia é uma planta, originária das salinas costeiras,
                  que apresenta naturalmente um sabor salgado.
                </P>
              </Container>
              <Container>
                <P fontSize="15px" laptopFontSize="18px" color="white">
                  Substituto natural do sal de cozinha nas preparações
                  culinárias, a Salicórnia apresenta em média 75% menos de sódio
                  em comparação com a mesma quantidade de sal marinho corrente.
                </P>
              </Container>
              <Container>
                <P fontSize="15px" laptopFontSize="18px" color="white">
                  Cultivada nas marinhas de Aveiro em meio natural, respeitando
                  o modo ancestral de obtenção desta planta para a nossa
                  alimentação, alinhado com os ritmos da natureza, a Salicórnia
                  qampo é transportada fresca para Campo Maior, onde é
                  desidratada e pronta para ir para o mercado.
                </P>
              </Container>
              <Container>
                <P fontSize="15px" laptopFontSize="18px" color="white">
                  Através de um processo de secagem patenteado, que permite
                  tornar a Salicórnia qampo compatível com outros temperos
                  desidratados, a marca disponibiliza uma gama de misturas que
                  permitem cozinhar facilmente diferentes alimentos em diversos
                  pratos e com todo o sabor.
                </P>
              </Container>
            </Container>
          </Container>
        </Container>
      </Container>
    </Container>
  );
};

export default SobreSalicornia;
