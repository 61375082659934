import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "styled-components";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

//-------------------
// Import Containers
//-------------------
import Navbar from "./Containers/Navbar";
import Footer from "./Containers/Footer";

//-------------------
// Import Pages
//-------------------
import Home from "./Pages/Home";

// --------------------
// Import utils
// --------------------
import GlobalStyles from "./util/Theme/GlobalStyles";
import { theme } from "./util/Theme";

import App from "./App";

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
