//Import React Components
import React, { useState, useEffect } from "react";
import A from "../../../components/A";
import Container from "../../../components/Container";
import Heading from "../../../components/Heading";

const MainSection = () => {
  const [width, setWidth] = useState();

  const getWindowWidth = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    getWindowWidth();
  }, []);

  console.log(width);

  return (
    <Container
      flex
      flexDirection="column-reverse"
      tabletFlexDirection="row"
      justifyContent="flex-start"
      margin="96px 0 0"
      tabletMargin="100px 0 0"
      laptopMargin="100px 0 0"
      backgroundImage="/hero/bannermobile.png"
      backgroundPosition="center"
      tabletBackgroundImage="url(/hero/bannerTop.png)"
      backgroundSize="cover"
      backgroundRepeat="no-repeat"
      height="550px"
      tabletHeight="320px"
      laptopHeight="450px"
      laptopLHeight="600px"
      desktopHeight="1000px"
    >
      {width > 768 ? (
        //Desktop Btn
        <Container
          margin="0 2rem"
          tabletMargin="0 2rem"
          laptopMargin="0 5rem"
          width="35%"
          flex
          flexDirection="column"
          justifyContent="center"
          gap="1rem"
        >
          <Heading
            textColor="#164428"
            fontFamily="secondary"
            level="1.5"
            levelTablet="1.5"
            laptopLevel="2.5"
            laptopLLevel="1.5"
          >
            Agora, JÁ PODE COMPRAR A SUA SALICÓRNIA DE ELEIÇÃO NA NOSSA LOJA
            ONLINE
          </Heading>
          <Container className="salicornia-btn-hero" width="fit-content">
            <A
              width="fit-content"
              textDecoration="none"
              target="_blank"
              href="https://pt.mydeltaq.com/pt/pt/mais-marcas?filtro_categorias=Salic%C3%B3rnia&utm_medium=Desktop&utm_campaign=ButtonDeltaQ"
            >
              <Container
                className="salicornia-btn-hero"
                width="fit-content"
                padding="0.5rem 1rem"
                backgroundColor="#164428"
              >
                <Heading
                  textColor="white"
                  fontFamily="secondary"
                  level="3"
                  levelTablet="3"
                  laptopLevel="3.5"
                  laptopLLevel="2"
                >
                  Compre já
                </Heading>
              </Container>
            </A>
          </Container>
        </Container>
      ) : (
        //Mobile Btn
        <Container
          margin="0 auto 2rem"
          tabletMargin="0 2rem"
          textAlign="left"
          width="80%"
          tabletWidth="35%"
          flex
          flexDirection="column"
          justifyContent="center"
          gap="1rem"
        >
          <Heading
            textColor="#164428"
            fontFamily="secondary"
            level="1.5"
            textAlign="center"
            tabletTextAlign="left"
            levelTablet="1.5"
            laptopLevel="2.5"
            laptopLLevel="1.5"
          >
            Agora, JÁ PODE COMPRAR A SUA SALICÓRNIA DE ELEIÇÃO NA NOSSA LOJA
            ONLINE
          </Heading>
          <Container width="fit-content" margin="0 auto" tabletMargin="0">
            <A
              width="fit-content"
              textDecoration="none"
              target="_blank"
              href="https://pt.mydeltaq.com/pt/pt/mais-marcas?filtro_categorias=Salic%C3%B3rnia&utm_medium=Mobile&utm_campaign=ButtonDeltaQ"
            >
              <Container
                className="salicornia-btn-hero"
                width="fit-content"
                padding="0.7rem 0.7rem"
                tabletPadding="0.5rem 1rem"
                backgroundColor="#164428"
              >
                <Heading
                  textColor="white"
                  fontFamily="secondary"
                  level="3"
                  levelTablet="3"
                  laptopLevel="3.5"
                  laptopLLevel="2"
                >
                  Compre já
                </Heading>
              </Container>
            </A>
          </Container>
        </Container>
      )}
    </Container>
  );
};

export default MainSection;
