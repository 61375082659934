const ClippingData = [
  {
    image: "clipping/visaoabril.png",
    title: "O que é e os benefícios deste substituto saudável do sal",
    paragraph:"Embora seja muito comum na costa portuguesa, não o era na gastronomia. Hoje, a salicórnia ganha espaço no mercado português como um produto próprio para salgar os pratos e cuja constituição conta com menos 75% de sódio do que o sal, tornando-a, por isso, ideal para pessoas com hipertensão ou outros problemas de coração",
    url: "https://visao.sapo.pt/visaosaude/2022-04-15-salicornia-o-que-e-e-os-beneficios-deste-substituto-saudavel-do-sal/",
  },
  {
    image: "clipping/sapo.jpg",
    title:
      "O que é a salicórnia? Conheça este poderoso aliado na diminuição do sal",
    paragraph:
      "A salicórnia (Salicornia ramosíssima) é uma planta originária das salinas costeiras, desde o Ártico até ao Mediterrâneo. Em Portugal, os principais locais onde é encontrada são as regiões da Ria de Aveiro, Ria Formosa e outras regiões do Algarve.",
    url: "https://lifestyle.sapo.pt/saude/peso-e-nutricao/artigos/salicornia-um-poderoso-aliado-na-diminuicao-do-sal",
  },
  {
    image: "clipping/atlas.jpg",
    title: "Uma alternativa saudável ao sal de cozinha",
    paragraph:
      "Os portugueses gostam de condimentar a comida com sal. O Inquérito Alimentar Nacional e de Atividade Física demonstra precisamente isso, já que no nosso país a média diária de ingestão de sal é de 7,3 gramas, estando acima do recomendado pela Organização Mundial da Saúde (5 gramas de sal por dia).",
    url: "https://www.atlasdasaude.pt/noticias/salicornia-tem-um-teor-de-sodio-75-mais-baixo-do-que-o-sal-marinho",
  },
  {
    image: "clipping/noticiasminuto.jpg",
    title:
      "Esta planta vai ajudá-lo a reduzir (drasticamente) o consumo de sal",
    paragraph:
      "Os portugueses gostam de condimentar a comida com sal. E até tudo bem. Só que consomem, em média, por dia, 7,3 gramas de sal, o que ultrapassa o valor recomendado pela Organização Mundial de Saúde de até cinco gramas diárias, conclui o Inquérito Alimentar Nacional e de Atividade Física.",
    url: "https://www.noticiasaominuto.com/lifestyle/1858968/salicornia-o-que-e-e-os-seus-beneficios-na-nossa-alimentacao",
  },
  {
    image: "clipping/incomum.jpg",
    title: "Salicórnia: a alternativa saudável ao sal de cozinha",
    paragraph:
      "Esta planta com sabor naturalmente salgado tem um teor de sódio 75% mais baixo do que o sal marinho.",
    url: "https://www.incomummagazine.com/pt/component/k2/salicornia-a-alternativa-saudavel-ao-sal-de-cozinha",
  },
  {
    image: "clipping/saposalicornia.jpeg",
    title: "Salicórnia: conheça aqui o que é e os seus (muitos) benefícios",
    paragraph:
      "Os portugueses gostam de condimentar a comida com sal. O Inquérito Alimentar Nacional e de Atividade Física demonstra precisamente isso, já que no nosso país a média diária de ingestão de sal é de 7,3 gramas, estando acima do recomendado pela Organização Mundial da Saúde (5 gramas de sal por dia).",
    url: "https://foreveryoung.sapo.pt/salicornia-conheca-aqui-o-que-e-e-os-seus-muitos-beneficios/",
  },
  {
    image: "clipping/ativa.png",
    title: "Salicórnia: um aliado na manutenção da saúde cardiovascular",
    paragraph:
      "O consumo elevado de sal, em particular o sódio que o constitui, é um dos fatores de risco para o desenvolvimento de hipertensão arterial, bem como a ingestão de álcool, o excesso de peso, hábitos tabágicos, entre outros fatores.",
    url: "https://activa.sapo.pt/diz-quem-sabe/2021-11-03-salicornia-um-aliado-na-manutencao-da-saude-cardiovascular/",
  },
  {
    image: "clipping/visao.jpeg",
    title:
      "Dieta Mediterrânica: O papel das pequenas plantas como a salicórnia",
    paragraph:
      "A Dieta Mediterrânica é tradicionalmente reconhecida pela diversidade de sabores e aromas das preparações culinárias, como resultado da combinação dos mais variados alimentos, preferencialmente frescos, da época e de proximidade, com os condimentos mais característicos da região em redor do mar Mediterrâneo, e que foram prevalecendo no tempo através da partilha de saberes, de sabores e de tradições.",
    url: "https://visao.sapo.pt/visaosaude/2021-12-23-dieta-mediterranica-o-papel-das-pequenas-plantas-como-a-salicornia/",
  },
  {
    image: "clipping/atlas.jpg",
    title: "Salicórnia: o que saber e como utilizar?",
    paragraph:
      "A salicórnia (Salicornia ramosíssima), também conhecida como “sal verde” ou “espargo do mar”, é uma planta halófita obrigatória, com caraterísticas morfológicas e fisiológicas que permitem que esta se desenvolva nas zonas costeiras do mediterrânico, em solos necessariamente com elevadas concentrações de sódio.",
    url: "https://www.atlasdasaude.pt/artigos/salicornia-o-que-saber-e-como-utilizar",
  },
  {
    image: "clipping/wh.jpeg",
    title: "Salicórnia? Saiba tudo sobre este aliado na diminuição do sal",
    paragraph:
      "A salicórnia (Salicornia ramosíssima), também conhecida como “sal verde” ou “espargo do mar”, é uma planta halófita obrigatória, com caraterísticas morfológicas e fisiológicas que permitem que esta se desenvolva nas zonas costeiras do mediterrânico, em solos necessariamente com elevadas concentrações de sódio.",
    url: "https://www.womenshealth.pt/salicornia-saiba-tudo-sobre-este-aliado-na-diminuicao-do-sal/saude/405689/",
  },
];

export default ClippingData;
