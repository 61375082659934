const recipesItems = [
  {
    main: {
      name: "green-section",
      bgImg: "/fundos_pintas/pintas_pretas.svg",
      textColor: "#71A060",
      heading: "SALICÓRNIA 100%",
      headingColor: "white",
      video: "Nr3_PfVIMzo",
      iconColor: "#70a160",
    },
    recipe: {
      featuredImg: "/receitas_imgs/receitasverde.png",
      bgImg: "/fundos_pintas/pintas_brancas.svg",
      headingTextColor: "#304633",
      heading: "OS MARAVILHOSOS ESPARGOS GRELHADOS COM OVOS ESTRELADOS",
      ingredients: ["Espargos 250g (62,5g por pessoa)", "4 Ovos"],
      preparation:
        "Começamos por grelhar os espargos e reservamos. De seguida, estrelamos os ovos na mesma frigideira dos espargos com um fio de azeite até ficarem no nosso ponto preferido. Temperamos com uma pitada de Salicórnia 100% para dar o toque final e perfeito de sabor. Podem-se servir sobre umas fatias torradas de pão para um pequeno-almoço cheio de energia e sem usar sal.",
    },
  },
  {
    main: {
      name: "red-section",
      bgImg: "/fundos_pintas/pintas_pretas.svg",
      textColor: "#BA4853",
      heading: "MIX SALICÓRNIA CARNE",
      headingColor: "white",
      video: "I2JfjIXGzTY",
      iconColor: "#bb4752",
    },
    recipe: {
      featuredImg: "/receitas_imgs/receitasvermelho.png",
      bgImg: "/fundos_pintas/pintas_brancas_vermelho.svg",
      headingTextColor: "#304633",
      heading: "OS DELICIOSOS HAMBÚRGUERES NO CHURRASCO",
      ingredients: [
        "Hamburgueres de vaca",
        "Pão",
        "Brioche",
        "Alface Iceberg",
        "Tomate",
        "Maionese",
      ],
      preparation:
        "Começamos por temperar os Hambúrgueres de ambos os lados com ao Mix Salicórnia Carne da qampo (1 colher de sopa por hambúrguer) e deixamos tomar gosto. Lavamos bem as folhas Alface e o Tomate, que cortamos em rodelas. Grelhamos os Hambúrgueres sobre as brasas até estarem no nosso ponto preferido. Abrimos o pão a meio e tostamos ligeiramente sobre as brasas e assim que estiver pronto, barramos com um pouco de Maionese. Depois basta montar o nosso delicioso Hambúrguer e desfrutar do melhor que a vida tem.",
    },
  },
  {
    main: {
      name: "yellow-section",
      bgImg: "/fundos_pintas/pintas_pretas.svg",
      textColor: "#E3AF62",
      heading: "MIX SALICÓRNIA MASSAS",
      headingColor: "white",
      video: "D2ka3Ayf1_M",
      iconColor: "#e4ae62",
    },
    recipe: {
      featuredImg: "/receitas_imgs/receitasamarelo.png",
      bgImg: "/fundos_pintas/pintas_brancas_amarelo.svg",
      headingTextColor: "#304633",
      heading: "UM BOM ESPARGUETE COM CAMARÕES SALTEADOS",
      ingredients: ["Esparguete", "Camarões descascados"],
      preparation:
        "Começamos por cozinhar o Esparguete respeitando o tempo na embalagem, sem usar sal na água, apenas um fio generoso de azeite, assim que estiver pronto, escorremos e reservamos. Numa frigideira já quente adicionamos um fio de azeite e colocamos os camarões e o tempero Mix Salicórnia Massa da qampo (1 colher de sopa por pessoa), envolvendo tudo muito bem até os camarões estarem no ponto. Neste momento adicionamos a massa já escorrida e salteamos tudo muito bem, até estar tudo bem envolvido. Está pronto a servir",
    },
  },
  {
    main: {
      name: "blue-section",
      bgImg: "/fundos_pintas/pintas_pretas.svg",
      textColor: "#E3AF62",
      heading: "MIX SALICÓRNIA PEIXE",
      headingColor: "white",
      video: "uMoX0WTrCh4",
      iconColor: "#37a2a8",
    },
    recipe: {
      featuredImg:"/receitas_imgs/receitasazul.png",
      bgImg: "/fundos_pintas/pintas_brancas_azul.svg",
      headingTextColor: "#304633",
      heading: "SABOROSO SALMÃO AO VAPOR EM PAPELOTE",
      ingredients: ["4 Lombos de Salmão", "1 Limão"],
      preparation:
        "Começamos por cortar papel de vegetal para forno em quadrados grandes. No centro colocamos o salmão, temperamos com uma colher de chá bem cheia de Mix Salicórnia Peixes, algumas raspas e sumo de limão, sem esquecer um fio generoso de azeite. Agora basta fechar o papelote (em forma de rebuçado, por exemplo) e levar ao forno a 220º durante cerca de 20min. Podemos acompanhar com umas cenouras bebé salteadas apenas em azeite e temperadas como Salicórnia 100% para termos a receita perfeita para uma refeição leve e saborosa, sem usar uma pitada de sal.",
    },
  },
];

export default recipesItems;
