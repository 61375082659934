// Import Utils
import FacebookIcon from "../../util/SVG/Socials/facebook";
import InstagramIcon from "../../util/SVG/Socials/instagram";

// Import Components
import A from "../../components/A";
import Container from "../../components/Container";
import Heading from "../../components/Heading";
import P from "../../components/P";
import SVG from "../../components/SVG";

function Footer() {
  return (
    <Container
      backgroundImage="/fundos_pintas/pintas_brancas.svg"
      backgroundRepeat="repeat"
      backgroundSize="auto"
    >
      <Container>
        <Container
          laptopTextAlign="left"
          textAlign="center"
          className="footer_section"
          flex
          center
          flexDirection="column"
          laptopFlexDirection="row"
          justifyContent="center"
          flexWrap="nowrap"
          laptopJustifyContent="center"
          alignItems="center"
          laptopPadding="5rem 5rem 3rem"
          padding="3rem 3rem"
          maxWidth="1000px"
          margin="0 auto"
        >
          <Container
            className="information_section"
            laptopWidth="80%"
            width="100%"
          >
            <Container className="information_1">
              <Heading
                laptopTextAlign="left"
                textAlign="center"
                margin="0 0 0.5rem"
                fontWeight="800"
                textColor="#304633"
                level="1.5"
              >
                NATURDELTA
              </Heading>
              <P color="white" margin="0" fontSize="15px">
                Sociedade Empreendedora de Agricultura, Turismo, Educação e
                Natureza, Lda.
              </P>
              <P fontMobile="5" color="white" margin="0" fontSize="15px">
                Fábrica-Zona Industrial Rua A, nº1
              </P>
              <P fontMobile="5" color="white" margin="0" fontSize="15px">
                7370-114 Campo Maior-Portugal
              </P>
            </Container>
            <Container className="information_2" margin="2rem 0">
              <A textDecoration="none" href="tel:00351268699200">
                <P fontMobile="5" color="white" margin="0" fontSize="15px">
                  Tel.: (+351) <strong>268 699 200</strong> (custo de chamada para a rede fixa nacional)
                </P>
              </A>
              <P fontMobile="5" color="white" margin="0" fontSize="15px">
                {" "}
                <strong>
                  <A color="white" textDecoration="none" target="_blank" href="www.qampo.pt">
                    www.qampo.pt
                  </A>{" "}
                  /{" "}
                  <A
                    color="white"
                    textDecoration="none"
                    href="mailto:naturdelta@delta-cafes.pt"
                  >
                    naturdelta@delta-cafes.pt
                  </A>
                </strong>
              </P>
            </Container>
          </Container>
          <Container
            width="25%"
            className="social_section"
            flex
            alignItems="center"
            justifyContent="center"
          >
            <A
              href="https://www.facebook.com/Salicornia_qampo-105658228550810"
              target="_blank"
            >
              <SVG
                flex
                justifyContent="center"
                alignItems="center"
                margin="5px"
                width="35"
                height="35"
                laptopWidth="25"
                laptopHeight="25"
                fill="#304633"
                background="white"
                borderRadius="50%"
                svg={<FacebookIcon />}
              />
            </A>
            <A
              href="https://www.instagram.com/salicornia_qampo/"
              target="_blank"
            >
              <SVG
                flex
                justifyContent="center"
                alignItems="center"
                margin="5px"
                width="35"
                height="35"
                laptopWidth="25"
                laptopHeight="25"
                fill="#304633"
                background="white"
                borderRadius="50%"
                svg={<InstagramIcon />}
              />
            </A>
          </Container>
        </Container>
      </Container>
    </Container>
  );
}

export default Footer;
