import React from "react";

function PlayIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      fill="#71a060"
      stroke="#71a060"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.5"
      className="feather feather-play"
      viewBox="0 0 24 24"
    >
      <path d="M5 3L19 12 5 21 5 3z"></path>
    </svg>
  );
}

export default PlayIcon;
