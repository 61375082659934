import styled from "styled-components";

import { device, theme } from "../../util/Theme";

const Wrapper = styled.div`
  width: ${({ width }) => width};
  max-width: ${({ maxWidth }) => maxWidth};
  height: ${({ height }) => height};
  max-height: ${({ maxHeight }) => maxHeight};
  min-height: ${({ minHeight }) => minHeight};
  flex-wrap: ${({ flexWrap }) => flexWrap};
  display: ${({ flex }) => (flex ? "flex" : "")};
  display: ${({ display }) => display};
  align-items: ${({ alignItems }) => alignItems};
  justify-content: ${({ justifyContent }) => justifyContent};
  flex-direction: ${({ flexDirection }) => flexDirection};
  font-family: ${({ fontFamily }) =>
    fontFamily === "secondary"
      ? theme.main.fonts.secondary
      : theme.main.fonts.primary};
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};
  position: ${({ position }) => position};
  top: ${({ top }) => top};
  left: ${({ left }) => left};
  right: ${({ right }) => right};
  bottom: ${({ bottom }) => bottom};
  z-index: ${({ zIndex }) => zIndex};
  background: ${({ background }) => background};
  background-color: ${({ backgroundColor }) => backgroundColor};
  background-image: url(${({ backgroundImage }) => backgroundImage});
  background-position: ${({ backgroundPosition }) => backgroundPosition};
  background-size: ${({ backgroundSize }) => backgroundSize};
  background-repeat: ${({ backgroundRepeat }) => backgroundRepeat};
  border-radius: ${({ borderRadius }) => borderRadius};
  overflow: ${({ overflow }) => overflow};
  position: ${({ position }) => position};
  box-shadow: ${({ boxShadow }) => boxShadow};
  transition: ${({ transition }) => transition};
  writing-mode: ${({ writingMode }) => writingMode};
  transform: ${({ transform }) => transform};
  text-align: ${({ textAlign }) => textAlign};
  border: ${({ border }) => border};
  border-style: ${({ borderStyle }) => borderStyle};
  border-bottom: ${({ borderBottom }) => borderBottom};
  display: ${({ display }) => display};
  overflow-y: ${({ overflowY }) => overflowY};
  cursor: ${({ cursor }) => cursor};
  gap: ${({ gap }) => gap};

  @media ${device.mobileM} {
    padding: ${({ mobileMPadding }) => mobileMPadding};
  }

  @media ${device.tablet} {
    display: ${({ tabletDisplay }) => tabletDisplay};
    flex-direction: ${({ tabletFlexDirection }) => tabletFlexDirection};
    background-image: ${({ tabletBackgroundImage }) => tabletBackgroundImage};
    background-radius: ${({ tabletBackgroundRadius }) =>
      tabletBackgroundRadius};
    poistion: ${({ tabletPosition }) => tabletPosition};
    width: ${({ tabletWidth }) => tabletWidth};
    height: ${({ tabletHeight }) => tabletHeight};
    padding: ${({ tabletPadding }) => tabletPadding};
    text-align: ${({ tabletTextAlign }) => tabletTextAlign};
    justify-content: ${({ tabletJustifyContent }) => tabletJustifyContent};
    margin: ${({ tabletMargin }) => tabletMargin};
    overflow: ${({ tabletOverflow }) => tabletOverflow};
    flex-wrap: ${({ tabletFlexWrap }) => tabletFlexWrap};
    top: ${({ tabletTop }) => tabletTop};
    left: ${({ tabletLeft }) => tabletLeft};
    right: ${({ tabletRight }) => tabletRight};
    bottom: ${({ tabletBottom }) => tabletBottom};
    min-height: ${({ tabletMinHeight }) => tabletMinHeight};
  }

  @media ${device.laptop} {
    display: ${({ laptopDisplay }) => laptopDisplay};
    flex-direction: ${({ laptopFlexDirection }) => laptopFlexDirection};
    width: ${({ laptopWidth }) => laptopWidth};
    height: ${({ laptopHeight }) => laptopHeight};
    padding: ${({ laptopPadding }) => laptopPadding};
    margin: ${({ laptopMargin }) => laptopMargin};
    justify-content: ${({ laptopJustifyContent }) => laptopJustifyContent};
    overflow: ${({ laptopOverflow }) => laptopOverflow};
    display: ${({ laptopDisplay }) => laptopDisplay};
    transform: ${({ laptopTransform }) => laptopTransform};
    writing-mode: ${({ laptopWritingMode }) => laptopWritingMode};
    position: ${({ laptopPosition }) => laptopPosition};
    top: ${({ laptopTop }) => laptopTop};
    left: ${({ laptopLeft }) => laptopLeft};
    right: ${({ laptopRight }) => laptopRight};
    bottom: ${({ laptopBottom }) => laptopBottom};
    flex-wrap: ${({ laptopFlexWrap }) => laptopFlexWrap};
    text-align: ${({ laptopTextAlign }) => laptopTextAlign};
    min-height: ${({ laptopMinHeight }) => laptopMinHeight};
  }

  @media ${device.laptopL} {
    display: ${({ laptopLDisplay }) => laptopLDisplay};
    flex-direction: ${({ laptopLFlexDirection }) => laptopLFlexDirection};
    width: ${({ laptopLWidth }) => laptopLWidth};
    height: ${({ laptopLHeight }) => laptopLHeight};
    padding: ${({ laptopLPadding }) => laptopLPadding};
    margin: ${({ laptopLMargin }) => laptopLMargin};
    justify-content: ${({ laptopLJustifyContent }) => laptopLJustifyContent};
    overflow: ${({ laptopLOverflow }) => laptopLOverflow};
    display: ${({ laptopLDisplay }) => laptopLDisplay};
    transform: ${({ laptopLTransform }) => laptopLTransform};
    writing-mode: ${({ laptopLWritingMode }) => laptopLWritingMode};
    top: ${({ laptopLTop }) => laptopLTop};
    left: ${({ laptopLLeft }) => laptopLLeft};
    right: ${({ laptopLRight }) => laptopLRight};
    bottom: ${({ laptopLBottom }) => laptopLBottom};
    flex-wrap: ${({ laptopLFlexWrap }) => laptopLFlexWrap};
    min-height: ${({ laptopLMinHeight }) => laptopLMinHeight};
  }

  @media ${device.desktop} {
    background-size: ${({ desktopBackgroundSize }) => desktopBackgroundSize};
    width: ${({ desktopWidth }) => desktopWidth};
    margin: ${({ desktopMargin }) => desktopMargin};
    height: ${({ desktopHeight }) => desktopHeight};
    top: ${({ desktopTop }) => desktopTop};
    left: ${({ desktopLeft }) => desktopLeft};
    right: ${({ desktopRight }) => desktopRight};
    bottom: ${({ desktopBottom }) => desktopBottom};
  }
`;

export default Wrapper;
