import * as React from "react";

function Armazenamento(props) {
  return (
    <svg
      id="qamposection-svg"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 200 200"
      xmlSpace="preserve"
      {...props}
      style={{backgroundColor:"rgba(111, 164, 78, 1)"}}
    >
      <style>{".prefix__st0{fill:#729b5e}"}</style>
      <path
        className="prefix__st0"
        d="M145.83 154.11c-1.46 0-2.65-1.18-2.65-2.65v-40.23H127.1v40.23c0 1.46-1.18 2.65-2.65 2.65-1.46 0-2.65-1.18-2.65-2.65v-42.87c0-1.46 1.18-2.65 2.65-2.65h21.37c1.46 0 2.65 1.18 2.65 2.65v42.87c0 1.46-1.18 2.65-2.64 2.65z"
      />
      <path
        className="prefix__st0"
        d="M179.31 154.11H90.97c-1.46 0-2.65-1.18-2.65-2.65V83.74c0-1.46 1.18-2.65 2.65-2.65 1.46 0 2.65 1.19 2.65 2.65v65.07h83.05V83.74c0-1.46 1.18-2.65 2.65-2.65 1.46 0 2.65 1.19 2.65 2.65v67.72a2.663 2.663 0 01-2.66 2.65z"
      />
      <path
        className="prefix__st0"
        d="M179.31 96.37h-44.17c-1.46 0-2.65-1.18-2.65-2.65 0-1.46 1.18-2.65 2.65-2.65h44.17c1.46 0 2.65 1.18 2.65 2.65 0 1.46-1.18 2.65-2.65 2.65z"
      />
      <path
        className="prefix__st0"
        d="M135.14 96.37c-1.46 0-2.65-1.18-2.65-2.65V55.06c0-1.46 1.18-2.65 2.65-2.65 1.46 0 2.65 1.19 2.65 2.65v38.66c0 1.46-1.18 2.65-2.65 2.65z"
      />
      <path
        className="prefix__st0"
        d="M135.14 96.37H90.97c-1.46 0-2.65-1.18-2.65-2.65 0-1.46 1.18-2.65 2.65-2.65h44.17c1.46 0 2.65 1.18 2.65 2.65 0 1.46-1.18 2.65-2.65 2.65z"
      />
      <path
        className="prefix__st0"
        d="M90.97 154.11H31.83c-1.46 0-2.65-1.18-2.65-2.65V83.74c0-1.46 1.18-2.65 2.65-2.65h59.14c1.46 0 2.65 1.19 2.65 2.65s-1.18 2.65-2.65 2.65h-56.5v62.43h56.5c1.46 0 2.65 1.18 2.65 2.65s-1.19 2.64-2.65 2.64z"
      />
      <path
        className="prefix__st0"
        d="M85 142.96H35.67c-1.46 0-2.65-1.18-2.65-2.65s1.18-2.65 2.65-2.65H85c1.46 0 2.65 1.18 2.65 2.65s-1.19 2.65-2.65 2.65zM115.09 142.96H92.58c-1.46 0-2.65-1.18-2.65-2.65s1.18-2.65 2.65-2.65h22.51c1.46 0 2.65 1.18 2.65 2.65s-1.19 2.65-2.65 2.65zM177.3 142.96h-23.45c-1.46 0-2.65-1.18-2.65-2.65s1.18-2.65 2.65-2.65h23.45c1.46 0 2.65 1.18 2.65 2.65s-1.19 2.65-2.65 2.65zM179.32 86.39c-1.06 0-2.06-.64-2.47-1.69-6.76-17.35-23.13-28.56-41.71-28.56-18.58 0-34.95 11.21-41.71 28.56A2.651 2.651 0 0190 86.21a2.651 2.651 0 01-1.51-3.43c7.55-19.4 25.86-31.93 46.64-31.93s39.08 12.53 46.64 31.93a2.647 2.647 0 01-2.45 3.61z"
      />
      <path
        className="prefix__st0"
        d="M31.83 86.39a2.651 2.651 0 01-2.47-3.61C36.92 63.38 55.22 50.84 76 50.84h59.14c1.46 0 2.65 1.18 2.65 2.65 0 1.46-1.18 2.65-2.65 2.65H76c-18.58 0-34.95 11.21-41.71 28.56-.4 1.05-1.4 1.69-2.46 1.69z"
      />
      <path
        className="prefix__st0"
        d="M49.21 86.39a2.651 2.651 0 01-2.47-3.61c7.55-19.4 25.86-31.93 46.64-31.93 1.46 0 2.65 1.18 2.65 2.65 0 1.46-1.18 2.65-2.65 2.65-18.58 0-34.95 11.21-41.71 28.56-.4 1.04-1.4 1.68-2.46 1.68z"
      />
      <path
        className="prefix__st0"
        d="M69.59 86.39a2.651 2.651 0 01-2.47-3.61c7.56-19.4 25.86-31.93 46.64-31.93 1.46 0 2.65 1.18 2.65 2.65 0 1.46-1.18 2.65-2.65 2.65-18.58 0-34.95 11.21-41.71 28.56-.4 1.04-1.4 1.68-2.46 1.68zM73.93 131.82c-.85 0-1.75-.07-2.68-.22a2.657 2.657 0 01-2.24-2.58c-.11-7.18 1.64-12.28 5.19-15.14 3.21-2.59 7.12-2.57 8.93-2.14 1.05.24 1.85 1.1 2.01 2.16.16 1.01 1.42 10-3.51 14.94-1.97 1.99-4.56 2.98-7.7 2.98zm6.16-14.92c-.8.12-1.75.44-2.62 1.15-1.81 1.49-2.87 4.4-3.11 8.47 1.5-.07 2.67-.54 3.53-1.41 1.9-1.91 2.26-5.64 2.2-8.21z"
      />
      <path
        className="prefix__st0"
        d="M71 142.96c-1.32 0-2.47-.99-2.62-2.34-.8-6.73.69-12.12.75-12.35a2.64 2.64 0 013.26-1.83c1.41.39 2.23 1.85 1.83 3.26-.02.07-1.26 4.68-.59 10.3.17 1.45-.87 2.77-2.32 2.94-.1.02-.2.02-.31.02z"
      />
      <path
        className="prefix__st0"
        d="M70.79 136.04c-1.26 0-2.37-.9-2.6-2.18-.02-.09-.91-4.54-4.38-5.67a2.656 2.656 0 01-1.71-3.33 2.656 2.656 0 013.33-1.71c6.56 2.12 7.91 9.46 7.97 9.78.25 1.44-.71 2.81-2.15 3.06-.16.04-.31.05-.46.05zM107.76 131.82c-.85 0-1.75-.07-2.68-.22a2.657 2.657 0 01-2.24-2.58c-.11-7.18 1.64-12.28 5.19-15.14 3.21-2.59 7.12-2.57 8.93-2.14 1.05.24 1.85 1.1 2.01 2.16.16 1.01 1.42 10-3.51 14.94-1.97 1.99-4.56 2.98-7.7 2.98zm6.16-14.92c-.8.12-1.75.44-2.62 1.15-1.81 1.49-2.87 4.4-3.11 8.47 1.5-.07 2.67-.54 3.53-1.41 1.9-1.91 2.26-5.64 2.2-8.21z"
      />
      <path
        className="prefix__st0"
        d="M104.83 142.96c-1.32 0-2.47-.99-2.62-2.34-.8-6.73.69-12.12.75-12.35.4-1.41 1.85-2.23 3.26-1.83 1.41.39 2.23 1.85 1.83 3.26-.02.07-1.26 4.68-.59 10.3.17 1.45-.87 2.77-2.32 2.94-.1.02-.2.02-.31.02z"
      />
      <path
        className="prefix__st0"
        d="M104.62 136.04c-1.26 0-2.37-.9-2.6-2.18-.02-.09-.91-4.54-4.39-5.67a2.656 2.656 0 01-1.71-3.33 2.656 2.656 0 013.33-1.71c6.56 2.12 7.91 9.46 7.97 9.78.25 1.44-.71 2.81-2.15 3.06-.15.04-.3.05-.45.05zM162.89 131.82c-.85 0-1.75-.07-2.68-.22a2.657 2.657 0 01-2.24-2.58c-.11-7.18 1.64-12.28 5.19-15.14 3.21-2.59 7.12-2.57 8.93-2.14 1.05.24 1.85 1.1 2.01 2.16.16 1.01 1.42 10-3.51 14.94-1.97 1.99-4.55 2.98-7.7 2.98zm6.16-14.92c-.8.12-1.75.44-2.62 1.15-1.81 1.49-2.87 4.4-3.11 8.47 1.5-.07 2.67-.54 3.53-1.41 1.91-1.91 2.26-5.64 2.2-8.21z"
      />
      <path
        className="prefix__st0"
        d="M159.97 142.96c-1.32 0-2.47-.99-2.62-2.34-.8-6.73.69-12.12.75-12.35.4-1.41 1.85-2.23 3.26-1.83 1.41.39 2.23 1.85 1.83 3.26-.02.07-1.26 4.68-.59 10.3.17 1.45-.87 2.77-2.32 2.94-.1.02-.21.02-.31.02z"
      />
      <path
        className="prefix__st0"
        d="M159.75 136.04c-1.26 0-2.37-.9-2.6-2.18-.02-.09-.91-4.54-4.38-5.67a2.656 2.656 0 01-1.71-3.33 2.656 2.656 0 013.33-1.71c6.56 2.12 7.91 9.46 7.97 9.78.25 1.44-.71 2.81-2.15 3.06-.15.04-.31.05-.46.05zM49.58 131.82c-.85 0-1.75-.07-2.68-.22a2.657 2.657 0 01-2.24-2.58c-.11-7.18 1.64-12.28 5.19-15.14 3.21-2.59 7.12-2.57 8.93-2.14 1.05.24 1.85 1.1 2.01 2.16.16 1.01 1.42 10-3.51 14.94-1.97 1.99-4.55 2.98-7.7 2.98zm6.16-14.92c-.8.12-1.75.44-2.62 1.15-1.81 1.49-2.87 4.4-3.11 8.47 1.5-.07 2.67-.54 3.53-1.41 1.91-1.91 2.27-5.64 2.2-8.21z"
      />
      <path
        className="prefix__st0"
        d="M46.66 142.96c-1.32 0-2.47-.99-2.62-2.34-.8-6.73.69-12.12.75-12.35a2.64 2.64 0 013.26-1.83 2.64 2.64 0 011.83 3.26c-.02.07-1.26 4.68-.59 10.3.17 1.45-.87 2.77-2.32 2.94-.1.02-.21.02-.31.02z"
      />
      <path
        className="prefix__st0"
        d="M46.44 136.04c-1.26 0-2.37-.9-2.6-2.18-.02-.11-.92-4.55-4.39-5.67a2.656 2.656 0 01-1.71-3.33 2.656 2.656 0 013.33-1.71c6.56 2.12 7.91 9.46 7.97 9.78.25 1.44-.71 2.81-2.15 3.06-.14.04-.29.05-.45.05z"
      />
    </svg>
  );
}

export default Armazenamento;
