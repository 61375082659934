// Import Components
import { useEffect, useState } from "react";
import Container from "../../../components/Container";
import Heading from "../../../components/Heading";
import SVG from "../../../components/SVG";
import mapStyles from "./mapStyles";
import Cross from "../../../util/SVG/searchicons/cross";
import Search from "../../../util/SVG/searchicons/search";
import axios from "axios";

const OndeComprar = () => {
  const [search, setSearch] = useState("");
  const [searchGeo, setSearchGeo] = useState("");
  const [data, setData] = useState([]);
  const [locatorObj, setlocatorObj] = useState("");

  let enterFlag = false;
  let center = { lat: 39.3, lng: -9.13333 };
  const APIKey = `${process.env.REACT_APP_GMAPS_API}`;
  let locator = {};
  let geocoder;
  let searchTerm;
  let filteredMarkers;

  const initMap = () => {
    const mapOptions = {
      center: center,
      zoom: 6.5,
      mapTypeControl: false,
      fullscreenControl: false,
      streetViewControl: false,
      styles: mapStyles,
    };

    // Initialize Map
    locator.map = new window.google.maps.Map(
      document.getElementById("map"),
      mapOptions
    );

    // Initialize Info Window
    locator.infoWindow = new window.google.maps.InfoWindow();

    // Create Markers
    locator.markers = data.map((location, index) => {
      const marker = new window.google.maps.Marker({
        icon: "/pins/pinsalicornia.png",
        position: new window.google.maps.LatLng(
          location.coords[0],
          location.coords[1]
        ),
        map: locator.map,
        title: location.name,
      });

      //Set the content of the infocards when clicked on the pin
      marker.addListener("click", () => {
        locator.infoWindow.close();
        locator.infoWindow.setContent(
          `
            <h3>${location.name}</h3>
            <p>${location.address}</p>
            <p>${location.postal} - ${location.location}</p>    
          `
        );
        locator.infoWindow.open(
          locator.markers[index].getMap(),
          locator.markers[index]
        );
      });

      //Close InfoWindow
      locator.map.addListener("click", () => {
        locator.infoWindow.close();
      });

      return marker;
    });

    //Create Autocomplete
    let input = document.getElementById("pac-input");
    locator.autocomplete = new window.google.maps.places.Autocomplete(input, {
      componentRestrictions: { country: "pt" },
    });
    window.google.maps.event.addDomListener(input, "keydown", function (e) {
      const gPlaceChoices = document.querySelector(".pac-container");
      if (null === gPlaceChoices) {
        return;
      }
      let visibility = window.getComputedStyle(gPlaceChoices).display;
      if ("none" !== visibility && e.keyCode === 13) {
        e.preventDefault();
      }
    });
    inputListener();
  };

  //Listen Autocomplete and submit button
  const inputListener = () => {
    locator.autocomplete.addListener("place_changed", fillInAddress);
  };

  //Change Input Value and Close Icon display
  const activeButton = (e) => {
    if (!searchTerm && !locatorObj) {
      setlocatorObj(locator);
    }
    searchTerm = e.target.value;

    setSearch(searchTerm);
  };

  //Fill Addresss with Gmaps'Autcomplete
  const fillInAddress = () => {
    setSearch(locator.autocomplete);
    setSearchGeo(locator.autocomplete.getPlace());
  };

  //Submit Button
  const getSubmit = (e) => {
    if (e.key == "Enter" || e == "click") {
      geocoder = new window.google.maps.Geocoder();
      filteredMarkers = false;
      let radius = 50;
      geocoder
        .geocode({ address: searchGeo.name, componentRestrictions:{country:'PT'} }, function (results, status) {
          if (status === window.google.maps.GeocoderStatus.OK) {
            let errorEl = document.getElementById("errorLocator");
            errorEl.style.display = "none";
            let searchCenter;
            searchCenter = new window.google.maps.LatLng(
              results[0].geometry.location.lat(),
              results[0].geometry.location.lng()
            );

            locatorObj.map.setCenter(searchCenter);
            let bounds = new window.google.maps.LatLngBounds();

            for (let i = 0; i < locatorObj.markers.length; i++) {
              let distance =
                window.google.maps.geometry.spherical.computeDistanceBetween(
                  new window.google.maps.LatLng(
                    locatorObj.markers[i].getPosition().lat(),
                    locatorObj.markers[i].getPosition().lng()
                  ),
                  new window.google.maps.LatLng(
                    searchCenter.lat(),
                    searchCenter.lng()
                  )
                ) / 1000;
              if (distance < radius) {
                filteredMarkers = 1;
                bounds.extend(locatorObj.markers[i].getPosition());
                // Set Map Zoom
                if (
                  window.document.getElementById("pac-input").value ==
                  "Algarve, Portugal"
                ) {
                  locatorObj.map.setZoom(10);
                } else {
                  locatorObj.map.setZoom(12);
                }
                // Set markers on map
                locatorObj.markers[i].setMap(locatorObj.map);
              } else {
                locatorObj.markers[i].setMap(null);
              }
            }
          } else {
            let errorEl = document.getElementById("errorLocator");
            errorEl.style.display = "block";
          }
        })
        .then(() => {
          if (!filteredMarkers) {
            locatorObj.map.setZoom(12);
            let errorEl = document.getElementById("errorLocator");
            errorEl.style.display = "block";
          }
        });
    }
  };

  function removeMarkers() {
    for (let i = 0; i < locatorObj.markers.length; i++) {
      locatorObj.markers[i].setMap(locatorObj.map);
    }
    locatorObj.map.setCenter(center);
    locatorObj.map.setZoom(6);
    let errorEl = document.getElementById("errorLocator");
    errorEl.style.display = "none";
    document.getElementById("pac-input").value = "";
    setSearch("");
    setSearchGeo("");

    filteredMarkers = false;
  }

  useEffect(() => {
    if (data.length === 0) {
      axios.get(`${process.env.REACT_APP_SERVER_URL}/pins`).then((response) => {
        if (response.status === 200) {
          setData(response.data);
        }
      });
    }

    const googleMapScript = document.createElement("script");
    googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${APIKey}&libraries=places,geocoding,geometry&components=country:US`;
    googleMapScript.async = true;
    window.document.head.appendChild(googleMapScript);

    googleMapScript.addEventListener("load", () => {
      initMap();
    });
  }, [data]);

  return (
    <Container
      id="ondecomprar"
      backgroundImage="/fundos_pintas/pintas_pretas.svg"
      backgroundRepeat="repeat"
      backgroundSize="auto"
      flex
      maxWidth="100%"
    >
      <Container margin="3rem 0 6rem" width="100%" flex justifyContent="center">
        <Container desktopWidth="30%" tabletWidth="70%" width="100%">
          <Container flex className="heading" margin="2rem 0 ">
            <Heading
              level="1"
              className="title_testemunhos"
              fontWeight="800"
              laptopLevel="0.8"
              textColor="#7C9A64"
              textAlign="center"
              margin="2rem"
            >
              Onde Comprar
            </Heading>
          </Container>
          <Container
            width="100%"
            flex
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Container
              width="100%"
              flex
              justifyContent="center"
              margin="0 0 1rem"
            >
              <Container
                flex
                width="80%"
                tabletWidth="72%"
                laptopWidth="52%"
                backgroundColor="white"
                justifyContent="space-between"
                padding="0.5rem"
              >
                <input
                  id="pac-input"
                  type="text"
                  placeholder="Search"
                  onChange={(e) => activeButton(e)}
                  onKeyDown={(e) => getSubmit(e)}
                  style={{
                    border: "none",
                    width: "100%",
                    padding: "0.5rem",
                  }}
                />
                <Container
                  id="cross-qwery"
                  display={searchGeo.name ? "flex" : "none"}
                  onClick={removeMarkers}
                  cursor="pointer"
                  justifyContent="center"
                  alignItems="center"
                >
                  <SVG svg={<Cross />} />
                </Container>
              </Container>
              <Container
                width="5%"
                backgroundColor="#7b9a64"
                flex
                justifyContent="center"
                alignItems="center"
                cursor="pointer"
                onClick={() => getSubmit("click")}
              >
                <Container id="location-submit">
                  <SVG svg={<Search />} />
                </Container>
              </Container>
            </Container>
            <Container width="90%" tabletWidth="80%" laptopWidth="60%">
              <Container>
                <Heading
                  id="errorLocator"
                  textColor="red"
                  fontWeight="500"
                  textAlign="center"
                  margin="0 0 1rem"
                  display="none"
                >
                  Não existem lojas na área selecionada.
                </Heading>
              </Container>
              <Container id="map" height="500px" width="100%"></Container>
            </Container>
          </Container>
        </Container>
      </Container>
    </Container>
  );
};

export default OndeComprar;
