//Import React Components
import React, { useState } from "react";

import "./receitas.css";

//Import Data
import recipesItems from "./recipesItems";

// Import Components
import Container from "../../../components/Container";
import Heading from "../../../components/Heading";
import P from "../../../components/P";
import SVG from "../../../components/SVG";
import PlayIcon from "../../../util/SVG/Icons/playicon";

import CloseIcon from "../../../util/SVG/Icons/closeicon";

export const toggleModalFunc = (video, modalBoxOpen, setModalBoxOpen, setVideoId) => {
  console.log({
    'video': video,
    'boxOpen': modalBoxOpen,
    'setBoxOpen': setModalBoxOpen,
    'videoId': setVideoId
  });
  let modalBoxFirstElement;
  let modalBoxSecondElement;
  modalBoxOpen.forEach((element, index) => {
    if (index === 0) {
      modalBoxFirstElement = !element;
    } else {
      if (element === "none") {
        modalBoxSecondElement = "video";
      } else if (element === "video") {
        modalBoxSecondElement = "none";
      }
      console.log(modalBoxFirstElement, modalBoxSecondElement);
      setModalBoxOpen([modalBoxFirstElement, modalBoxSecondElement]);
      //modalBoxFirstElement === true ? (document.body.style.overflowY = "hidden") : (document.body.style.overflowY = "inherit");
    }
  });
  setVideoId(video);
};

const Recipes = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [videoId, setVideoId] = useState();
  const [loaded, setLoaded] = useState(false);
  const [modalBoxOpen, setModalBoxOpen] = useState([false, "none"]);
  const [modalType, setModalType] = useState("");

  const recipesItemsVideosOnly = [
    {
      main: {
        name: "green-section",
        bgImg: "/fundos_pintas/pintas_pretas.svg",
        headerBgImg: "/fundos_pintas/pintas_brancas.svg",
        textColor: "#71A060",
        heading: "Mix Salicórnia Peixe",
        subHeading: "Lombos de pescada com legumes",
        headingColor: "white",
        video: "ocX0-I6ajJQ",
        iconColor: "#70a160",
        featuredImg: "/receitas_imgs/peixe.png",
      },
    },
    {
      main: {
        name: "green-section",
        bgImg: "/fundos_pintas/pintas_pretas.svg",
        headerBgImg: "/fundos_pintas/pintas_brancas_vermelho.svg",
        textColor: "#BA4853",
        heading: "Mix Salicórnia Carne",
        subHeading: "Espetadas deliciosas na Frigideira",
        headingColor: "white",
        video: "A5AcxMuir70",
        iconColor: "#bb4752",
        featuredImg: "/receitas_imgs/carne.png",
      },
    },
    {
      main: {
        name: "green-section",
        bgImg: "/fundos_pintas/pintas_pretas.svg",
        headerBgImg: "/fundos_pintas/pintas_brancas_amarelo.svg",
        textColor: "#BA4853",
        heading: "MIX SALICÓRNIA MASSAS",
        subHeading: "Receitas com todo o sabor",
        headingColor: "white",
        video: "_RF0deLjfKk",
        iconColor: "#e4ae62",
        featuredImg: "/receitas_imgs/massas.png",
      },
    },
    {
      main: {
        name: "green-section",
        bgImg: "/fundos_pintas/pintas_pretas.svg",
        headerBgImg: "/fundos_pintas/pintas_brancas.svg",
        textColor: "#71A060",
        heading: "Mix Salicórnia Peixe",
        subHeading: "Receitas com todo o sabor",
        headingColor: "white",
        video: "N2cm7ea-SEA",
        iconColor: "#70a160",
        featuredImg: "/receitas_imgs/peixe-2.png",
      },
    },
    {
      main: {
        name: "green-section",
        bgImg: "/fundos_pintas/pintas_pretas.svg",
        headerBgImg: "/fundos_pintas/pintas_brancas_vermelho.svg",
        textColor: "#BA4853",
        heading: "Mix Salicórnia Carne",
        subHeading: "Receitas com todo o sabor",
        headingColor: "white",
        video: "9A6CjcnNCgA",
        iconColor: "#bb4752",
        featuredImg: "/receitas_imgs/carne-2.png",
      },
    }
  ];

  function timeFunction(video) {
    toggleModalFunc(video, modalBoxOpen, setModalBoxOpen, setVideoId);
    setTimeout(function () {
      setLoaded(true);
      return;
    }, 2000);
  }

  return (
    <>
      {modalBoxOpen[0] === true && modalBoxOpen[1] === "video" ? (
        <Container
          backgroundColor="black"
          padding="50px 0 0 "
          height="100vh"
          width="100%"
          position="fixed"
          top="0"
          left="0"
          zIndex="99999"
          flex
          justifyContent="center"
          alignItems="center"
        >
          <SVG
            onClick={() => toggleModalFunc("", modalBoxOpen, setModalBoxOpen, setVideoId)}
            width="24"
            height="24"
            svg={<CloseIcon />}
            position="absolute"
            right="15%"
            top="10%"
            cursor="pointer"
          />
          {loaded ? (
            <Container
              width="450px"
              height="200px"
              tabletWidth="600px"
              tabletHeight="300px"
              laptopWidth="1000px"
              laptopHeight="550px"
            >
              <iframe
                id="i_frame"
                title={videoId}
                width="100%"
                height="100%"
                frameBorder="0"
                src={`https://www.youtube.com/embed/${videoId}`}
              />
            </Container>
          ) : (
            <Container
              className="loader"
              margin="auto"
              width="40px"
              height="40px"
            ></Container>
          )}
        </Container>
      ) : null}
      {recipesItems.map((item, index) => {
        return (
          <Container
            key={`Container ${index}`}
            backgroundImage={item.main.bgImg}
          >
            <Container
              key={`Sub-Container ${index}`}
              width="100%"
              flex
              flexDirection="column"
              laptopFlexDirection="row"
              maxWidth="1400px"
              margin="0 auto"
            >
              <Container
                key={`Margin-Container ${index}`}
                margin="7%"
              ></Container>
              <Container
                key={`Recipes-Container ${index}`}
                tabletPadding="0 3rem"
                laptopPadding="0"
              >
                <Container
                  key={`Flex-Container ${index}`}
                  width="100%"
                  height="100%"
                  flex
                  flexDirection="column"
                  laptopFlexDirection="row"
                >
                  <Container
                    key={`Image ${index}`}
                    laptopWidth="40%"
                    className="modal_box"
                    width="100%"
                    height="400px"
                    tabletHeight="400px"
                    laptopHeight="100%"
                    laptopLHeight="100%"
                    backgroundImage={item.recipe.featuredImg}
                    backgroundPosition="center"
                    backgroundSize="cover"
                    cursor="pointer"
                    position="relative"
                    onClick={() => timeFunction(item.main.video)}
                  >
                    <Container
                      className={`video-play-button ${item.main.name}`}
                      height="120px"
                      width="120px"
                      flex
                      justifyContent="center"
                      alignItems="center"
                      borderRadius="50%"
                    >
                      <SVG
                        width="30"
                        height="30"
                        fill={item.main.iconColor}
                        stroke={item.main.iconColor}
                        svg={<PlayIcon />}
                      ></SVG>
                    </Container>
                  </Container>
                  <Container
                    key={`Text-Container ${index}`}
                    laptopWidth="60%"
                    width="100%"
                    backgroundImage={item.recipe.bgImg}
                    backgroundRepeat="repeat"
                    backgroundSize="auto"
                    laptopPadding="2rem 5rem"
                    laptopLPadding="2rem 12rem 2rem 4rem"
                    textAlign="justify"
                  >
                    <Container
                      key={`Heading-Container ${index}`}
                      laptopPadding="0"
                      padding="3rem"
                    >
                      {item.main.heading ? (
                        <Heading
                          key={`Heading ${index}`}
                          textColor={item.main.headingColor}
                          fontWeight="800"
                          level="0.7"
                          fontFamily="secondary"
                          margin="1rem 0"
                        >
                          {item.main.heading}
                        </Heading>
                      ) : (
                        <P key={`Other-Heading ${index}`}></P>
                      )}
                      <Heading
                        key={`Sub-Heading ${index}`}
                        textColor={item.recipe.headingTextColor}
                        fontWeight="800"
                        level="1"
                        lineHeight="1.5"
                        fontFamily="secondary"
                      >
                        {item.recipe.heading}
                      </Heading>
                      <P
                        key={`Paragraph ${index}`}
                        lineHeight="1.5"
                        letterSpacing="1px"
                        color="white"
                        flex
                        margin="1rem 0"
                      >
                        <strong
                          key={`Strong ${index}`}
                          style={{ padding: "0 .5rem 0 0" }}
                        >
                          Ingredientes:
                        </strong>

                        {item.recipe.ingredients.map((ingredient, index) => {
                          return (
                            <span key={`Main-Span ${index}`}>
                              <span
                                key={`Span-Ingredient ${index}`}
                                style={{ display: "inline-flex" }}
                              >
                                {ingredient}
                                {index < item.recipe.ingredients.length - 1 ? (
                                  <span
                                    key={`Span ${index}`}
                                    style={{ padding: "0 .5rem" }}
                                  >
                                    |
                                  </span>
                                ) : (
                                  <span key={`Other-Span ${index}`}></span>
                                )}
                              </span>
                            </span>
                          );
                        })}
                      </P>
                      <P
                        key={`Preparacao-Paragraph ${index}`}
                        lineHeight="1.5"
                        letterSpacing="1px"
                        color="white"
                      >
                        <strong
                          key={`Strong-Preparacao ${index}`}
                          style={{ padding: "0 .5rem 0 0" }}
                        >
                          Preparação:
                        </strong>
                        {item.recipe.preparation}
                      </P>
                    </Container>
                  </Container>
                </Container>
              </Container>
            </Container>
          </Container>
        );
      })}

    <Container
            key={`Container 1`}
            backgroundImage="/fundos_pintas/pintas_pretas.svg"
            laptopPadding="0"
            tabletPadding="0 3rem"
          >
            <Container
              key={`Sub-Container 1`}
              width="100%"
              flex
              flexDirection="column"
              laptopFlexDirection="row"
              maxWidth="1400px"
              margin="0 auto"
              flexWrap="wrap"
              justifyContent="end"
            >
              <Container
                key={`Margin-Container 1`}
                margin="7%"
              ></Container>
              {recipesItemsVideosOnly.map((item, index) => {
                let width = "43%"; // default width

                if (index === 2) {
                  width = "86%"; // set width to 50% for the first item
                }
              return (
                <>
                <Container
                  key={`Container ${index}`}
                  backgroundImage={item.main.bgImg}
                  laptopWidth={width}
                  width="100%"
                >
                  <Container
                    key={`Text-Container ${index}`}
                    width="100%"
                    backgroundImage={item.main.headerBgImg}
                    backgroundRepeat="repeat"
                    backgroundSize="auto"
                    textAlign="justify"
                  >
                    <Container
                    key={`Text-Container ${index}`}
                    laptopPadding="1rem 2rem"
                    textAlign="justify"
                    padding="0 3rem"
                  >
                    <Heading
                    key={`Heading ${index}`}
                    textColor={item.main.headingColor}
                    fontWeight="800"
                    level="0.7"
                    fontFamily="secondary"
                    margin="1rem 0"
                  >
                    {item.main.heading}
                  </Heading>
                  <Heading
                        key={`Sub-Heading ${index}`}
                        textColor={item.main.headingTextColor}
                        fontWeight="800"
                        level="1"
                        lineHeight="1.5"
                        fontFamily="secondary"
                      >
                        {item.main.subHeading}
                      </Heading>
                    </Container>
                    
                    </Container>
                  
                  <Container
                    key={`Sub-Container ${index}`}
                    width="100%"
                    flex
                    flexDirection="column"
                    laptopFlexDirection="row"
                    maxWidth="1400px"
                    margin="0 auto"
                  >
                    <Container
                      key={`Recipes-Container ${index}`}
                      laptopPadding="0"
                      width="100%"
                      height="300px"
                    >
                      <Container
                        key={`Flex-Container ${index}`}
                        width="100%"
                        height="100%"
                        flex
                        flexDirection="column"
                        laptopFlexDirection="row"
                      >
                        <Container
                          key={`Image ${index}`}
                          laptopWidth="100%"
                          className="modal_box"
                          width="100%"
                          height="400px"
                          tabletHeight="400px"
                          laptopHeight="100%"
                          laptopLHeight="100%"
                          backgroundImage={item.main.featuredImg}
                          backgroundPosition="center"
                          backgroundSize="cover"
                          cursor="pointer"
                          position="relative"
                          onClick={() => timeFunction(item.main.video)}
                        >
                          <Container
                            className={`video-play-button ${item.main.name}`}
                            height="120px"
                            width="120px"
                            flex
                            justifyContent="center"
                            alignItems="center"
                            borderRadius="50%"
                          >
                            <SVG
                              width="30"
                              height="30"
                              fill={item.main.iconColor}
                              stroke={item.main.iconColor}
                              svg={<PlayIcon />}
                            ></SVG>
                          </Container>
                        </Container>
                      </Container>
                    </Container>
                  </Container>
                </Container>  
                
                <Container
                key={`Margin-Container 1`}
                margin="7%"
                laptopDisplay="none"
                display="block"
              ></Container>
              </>
              
              );
            })}
            </Container>
      </Container>
      
    </>
  );
};

export default Recipes;
