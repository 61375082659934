// Import Components
import Container from "../../../components/Container";
import Heading from "../../../components/Heading";
import P from "../../../components/P";
import SVG from "../../../components/SVG";
import PlayIcon from "../../../util/SVG/Icons/playicon";
import { toggleModalFunc } from "./display";
import React, { useState } from "react";
import CloseIcon from "../../../util/SVG/Icons/closeicon";

const ReceitasIntro = () => { 
  const [isLoading, setIsLoading] = useState(false);
  const [videoId, setVideoId] = useState();
  const [loaded, setLoaded] = useState(false);
  const [modalBoxOpen, setModalBoxOpen] = useState([false, "none"]);
  const [modalType, setModalType] = useState("");

  function timeFunction(video) {
    toggleModalFunc(video, modalBoxOpen, setModalBoxOpen, setVideoId);
    setTimeout(function () {
      setLoaded(true);
      return;
    }, 2000);
  }

  const item = {
    main: {
      name: "intro-section-video",
      bgImg: "/fundos_pintas/pintas_pretas.svg",
      textColor: "#71A060",
      heading: "SALICÓRNIA 100%",
      headingColor: "white",
      video: "v9mmK6nslEA",
      iconColor: "#37a2a8",
      featuredImg: "/receitas_imgs/utilizar.png",
    },
  };

  return (
    <>
    {modalBoxOpen[0] === true && modalBoxOpen[1] === "video" ? (
      <Container
        backgroundColor="black"
        padding="50px 0 0 "
        height="100vh"
        width="100%"
        position="fixed"
        top="0"
        left="0"
        zIndex="99999"
        flex
        justifyContent="center"
        alignItems="center"
      >
        <SVG
          onClick={() => toggleModalFunc("", modalBoxOpen, setModalBoxOpen, setVideoId)}
          width="24"
          height="24"
          svg={<CloseIcon />}
          position="absolute"
          right="15%"
          top="10%"
          cursor="pointer"
        />
        {loaded ? (
          <Container
            width="450px"
            height="200px"
            tabletWidth="600px"
            tabletHeight="300px"
            laptopWidth="1000px"
            laptopHeight="550px"
          >
            <iframe
              id="i_frame"
              title={videoId}
              width="100%"
              height="100%"
              frameBorder="0"
              src={`https://www.youtube.com/embed/${videoId}`}
            />
          </Container>
        ) : (
          <Container
            className="loader"
            margin="auto"
            width="40px"
            height="40px"
          ></Container>
        )}
      </Container>
    ) : null}
    <Container backgroundImage="/fundos_pintas/pintas_pretas.svg" id="receitas">
      <Container
        flex
        flexDirection="column"
        laptopFlexDirection="row"
        padding="3rem"
        laptopPadding="5rem 5rem 3rem"
        laptopLPadding="5rem 12.5rem 3rem 12.5rem"
        maxWidth="1000px"
        margin="0 auto"
      >
        <Container
          laptopWidth="40%"
          tabletWidth="50%"
          width="100%"
          flex
          flexDirection="column"
          laptopFlexDirection="column"
          laptopMargin="0 5rem 0 0"
          margin="0rem 0rem 2rem 0rem"
        >
          <Container>
            <Heading
              textColor="#304633"
              fontWeight="800"
              level="0.9"
              laptopLevel="1"
            >
              Receitas
            </Heading>
            <Heading
              margin="0.5rem 0"
              textColor="#71A060"
              fontWeight="800"
              level="0.9"
              laptopLevel="1"
            >
              Salicornia qampo
            </Heading>
          </Container>
          <Container flex justifyContent="flex-end" margin="1rem 0 0">
            <Container>
              <Container height="100px">
                <img height="100%" src="/page_elements/colher.png" alt="ramo" />
              </Container>
              <P
                fontWeight="800"
                margin="5px 0"
                fontSize="20px"
                color="#304633"
                fontFamily="secondary"
              >
                DOSEAMENTO
              </P>
              <P
                fontWeight="800"
                margin="0"
                color="#304633"
                fontFamily="secondary"
              >
                1 COLHER DE CHÁ
              </P>
              <P
                fontWeight="800"
                margin="0"
                color="#304633"
                fontFamily="secondary"
              >
                POR PESSOA
              </P>
            </Container>
          </Container>
        </Container>
        <Container laptopWidth="60%" width="100%">
          <P
            lineHeight="1.5"
            letterSpacing="1px"
            color="#304633"
            fontWeight="900"
          >
          A Salicórnia é um ótimo tempero que tem como particularidade a substituição do 
          sal nas preparações culinárias. Para isso deixamos aqui algumas dicas 
          para a sua utilização em pleno:
          </P>
          <P lineHeight="1.5" letterSpacing="1px">
          Espalhar o tempero uniformemente sobre a carne ou peixe, 
          de ambos os lados e de maneira a cobrir a totalidade da 
          superfície. Pode também utilizar em Estufados e outros pratos 
          de panela. Como não dilui como o sal, podemos ir corrigindo o 
          tempero, provando e adicionando mais caso seja necessário, até 
          estar a seu gosto.
          </P>
          <P lineHeight="1.5" letterSpacing="1px">
          Uma boa medida é usar aproximadamente uma colher de chá por 
          pessoa quando utilizar Salicórnia 100% da qampo e uma colher de 
          sopa por pessoa quando utilizar os Mix Salicórnia Carne, Peixe 
          ou Massa da qampo.
          </P>
          <Container
              key="1"
              laptopWidth="100%"
              className="modal_box"
              width="100%"
              height="168px"
              tabletHeight="168px"
              laptopHeight="168px"
              laptopLHeight="168px"
              backgroundImage={item.main.featuredImg}
              backgroundPosition="center"
              backgroundSize="cover"
              cursor="pointer"
              position="relative"
              onClick={() => timeFunction(item.main.video)}
            >
              <Container
                className={`video-play-button ${item.main.name}`}
                height="120px"
                width="120px"
                flex
                justifyContent="center"
                alignItems="center"
                borderRadius="50%"
              >
                <SVG
                  width="30"
                  height="30"
                  fill={item.main.iconColor}
                  stroke={item.main.iconColor}
                  svg={<PlayIcon />}
                ></SVG>
              </Container>
            </Container>
        </Container>
      </Container>
    </Container>
    </>
  );
};

export default ReceitasIntro;
