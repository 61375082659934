import React, { useState, useEffect } from "react";

import Container from "../../components/Container";
import TopSVG from "../../util/SVG/backtotop";

const BacktoTop = () => {
  const [widthScroll, setWidthScroll] = useState(0);
  function handleScroll() {
    let position = window.scrollY;
    let height = window.innerHeight;
    let bottom = document.body.scrollHeight;
    setWidthScroll((position * 100) / (bottom - height));
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  });

  function goTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  return (
    <Container
      className="back-to-top-button"
      onClick={goTop}
      display={widthScroll > 0 ? "block" : "none"}
      position="fixed"
      zIndex="10000"
      bottom="10%"
      right="10%"
      backgroundColor="#e9e6e6"
      borderRadius="50%"
      boxShadow="5px 5px 1px black;"
      padding="0.5rem 0.7rem"
      cursor="pointer"
    >
      <TopSVG />
    </Container>
  );
};

export default BacktoTop;
