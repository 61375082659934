import * as React from "react";

function ProducaoAgricola(props) {
  return (
    <svg
      id="qamposection-svg"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 200 200"
      xmlSpace="preserve"
      {...props}
      style={{backgroundColor:"rgb(49, 161, 170)"}}
    >
      <style>{".prefix__st0{fill:white}"}</style>
      <path
        className="prefix__st0"
        d="M125.24 117.65c-.8 0-1.6-.36-2.12-1.06a2.65 2.65 0 01.53-3.71c11.33-8.51 18.29-10.72 24.85-7.88.34.15.83.42 1.44.76l.26.15c1.28.7 1.75 2.31 1.04 3.59a2.635 2.635 0 01-3.59 1.04l-.32-.18c-.3-.17-.73-.42-.95-.52-3.76-1.63-8.52-1.02-19.55 7.26-.48.38-1.03.55-1.59.55z"
      />
      <path
        className="prefix__st0"
        d="M121.91 110.24c-.98 0-1.92-.54-2.38-1.48a2.65 2.65 0 011.21-3.54c6.18-3.03 11.35-3.84 15.35-2.39 1.05.38 2 .85 2.81 1.4a2.64 2.64 0 01.71 3.67 2.65 2.65 0 01-3.68.71c-.45-.3-1-.58-1.65-.81-2.57-.93-6.45-.18-11.22 2.16-.36.19-.76.28-1.15.28z"
      />
      <path
        className="prefix__st0"
        d="M91.18 137.31c-1.1 0-2.12-.69-2.5-1.78-.48-1.38.25-2.89 1.64-3.37.64-.22 1.21-.43 1.75-.62 3.29-1.19 5.46-1.97 14.78-3.35 8.1-1.2 15.27-4.18 17.45-7.26.6-.85.58-1.39.51-1.73-.59-3.01-3.83-3.56-21.84-.86-13.09 1.96-19.26.31-25.22-1.28-4.49-1.2-8.72-2.33-16.16-1.67-3.34.3-9.58 1.29-14.6 2.08-2.16.34-4.15.66-5.7.89a2.644 2.644 0 01-.77-5.23c1.54-.23 3.5-.54 5.64-.88 5.36-.85 11.44-1.82 14.96-2.13 8.37-.74 13.26.56 17.99 1.83 5.69 1.52 11.07 2.95 23.08 1.16 15.69-2.35 26.05-3.9 27.82 5.08.39 1.97-.09 3.98-1.38 5.81-3.69 5.22-13.26 8.3-21 9.45-8.8 1.3-10.67 1.98-13.76 3.09-.56.2-1.15.42-1.82.65-.29.07-.58.12-.87.12z"
      />
      <path
        className="prefix__st0"
        d="M85.5 154.65c-6.04 0-11.64-.58-17.53-1.18-7.96-.82-16.19-1.67-26.97-1.32-1.42.04-2.68-1.1-2.73-2.56a2.647 2.647 0 012.56-2.73c11.14-.36 19.93.55 27.69 1.35 6.18.64 12.03 1.23 18.28 1.15 14.56-.21 39.19-8.12 42.35-10.35.9-.63 2.01-1.39 3.26-2.24 4.22-2.88 10-6.82 14.96-10.96 2.23-1.86 9.03-7.52 9.12-10.88.01-.41-.07-.92-.76-1.56-3.47-3.22-10.04.01-24.25 11.92-5.03 4.22-15.68 6.41-16.87 6.64-1.43.28-2.82-.65-3.11-2.08a2.65 2.65 0 012.08-3.11c2.89-.57 11.12-2.67 14.5-5.51 12.63-10.59 23.69-18.78 31.26-11.74 1.66 1.54 2.5 3.47 2.45 5.58-.14 5.05-5.38 10.1-11.03 14.8-5.16 4.3-11.06 8.32-15.37 11.26-1.23.84-2.31 1.58-3.2 2.2-4.73 3.33-30.75 11.1-45.32 11.31-.46.01-.92.01-1.37.01zM98.07 104.51c-.9 0-1.78-.46-2.28-1.29a2.64 2.64 0 01.91-3.62c1.35-1 3.53-8.54 1.12-14.07-6.82-15.67-4.1-25.63-3.01-28.49-1.22-9.36.56-17.05 5.29-22.85 7.63-9.35 20.08-10.17 20.61-10.2 1.29-.07 2.43.78 2.73 2.03.15.63 3.58 15.62-4.44 25.62-4.27 5.32-10.85 8.14-19.59 8.38-.85 3.11-2.04 11.18 3.27 23.39 2.71 6.24 1.58 17.84-3.25 20.72-.43.26-.9.38-1.36.38zm20.61-74.91c-3.49.66-10.14 2.58-14.5 7.96-3.49 4.3-4.93 10.05-4.3 17.14 6.79-.3 11.82-2.44 14.97-6.36 4.76-5.9 4.36-14.59 3.83-18.74z"
      />
      <path
        className="prefix__st0"
        d="M86.66 81.81c-2.94 0-5.71-.63-8.3-1.89-10.95-5.33-14.34-19.98-14.48-20.6-.3-1.35.49-2.7 1.81-3.11.26-.08 25.56-7.51 34.17 19.41.4 1.26-.19 2.62-1.38 3.19-4.18 2-8.13 3-11.82 3zM69.8 60.72c1.35 3.96 4.63 11.41 10.88 14.44 3.8 1.84 8.27 1.78 13.32-.18-5.99-15.19-18.71-15.09-24.2-14.26zM123.2 115.83c-.16 0-.32-.01-.49-.04a2.643 2.643 0 01-2.12-3.08c.33-1.77-.86-3.19-1.6-3.89-2.22-2.1-5.74-2.94-8.97-2.15-.99.24-2.04-.1-2.68-.9-.91-1.12-3.25-1.78-5.95-1.71-2.96.09-4.87 1-5.19 1.44a2.635 2.635 0 01-3.26 1.03c-2.86-1.19-8.32-1.18-10.96 2.61a2.64 2.64 0 01-2.74 1.07c-5.82-1.29-9.6 3.48-9.64 3.53a2.636 2.636 0 01-3.71.47 2.636 2.636 0 01-.47-3.71c.22-.29 5.21-6.6 13.31-5.73 3.73-3.98 9.75-4.98 14.52-3.71 2.43-1.8 6.06-2.19 7.32-2.27 3.92-.25 7.4.65 9.66 2.44 4.51-.64 9.25.75 12.4 3.72 2.59 2.44 3.74 5.61 3.17 8.7a2.642 2.642 0 01-2.6 2.18z"
      />
    </svg>
  );
}

export default ProducaoAgricola;
