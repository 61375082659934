import styled from "styled-components";

import { device, theme } from "../../util/Theme";

// const handleTextColor = ({ textColor, theme }) => {
//   switch (textColor) {
//     case "primary":
//       return theme.main.colors.primary;
//     case "secondary":
//       return theme.main.colors.secondary;
//     case "white":
//       return "#fdfdfd";
//     default:
//       return theme.main.colors.tertiary;
//   }
// };

const Wrapper = styled.div`
  display: ${({ display }) => display};
  justify-content: ${({ justifyContent }) => justifyContent};
  width: ${({ width }) => width};
  font-family: ${({ fontFamily }) =>
    fontFamily === "secondary"
      ? theme.main.fonts.secondary
      : theme.main.fonts.primary};
  font-size: ${({ level }) => 2 / level}em;
  font-weight: ${({ fontWeight }) => fontWeight};
  text-align: ${({ textAlign }) => textAlign};
  color: ${({ textColor }) => textColor};
  text-transform: ${({ uppercase }) => (uppercase ? "uppercase" : "")};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  line-height: ${({ lineHeight }) => lineHeight};

  @media ${device.mobileL} {
    justify-content: ${({ mobileLjustifyContent }) => mobileLjustifyContent};
  }
  @media ${device.tablet} {
    width: ${({ tabletWidth }) => tabletWidth};
    text-align: ${({ tabletTextAlign }) => tabletTextAlign};
    font-size: ${({ levelTablet }) => 2 / levelTablet}em;
  }

  @media ${device.laptop} {
    text-align: ${({ laptopTextAlign }) => laptopTextAlign};
    font-size: ${({ laptopLevel }) => 4 / laptopLevel}em;
    margin: ${({ laptopMargin }) => laptopMargin};
    width: ${({ laptopWidth }) => laptopWidth};
  }

  @media ${device.laptopL} {
    font-size: ${({ laptopLLevel }) => 4 / laptopLLevel}em;
    margin: ${({ laptopLMargin }) => laptopLMargin};
    width: ${({ laptopLWidth }) => laptopLWidth};
  }
`;

export default Wrapper;
