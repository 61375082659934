import React from "react";

import { Link } from "react-router-dom";
import Container from "../../components/Container";
import Heading from "../../components/Heading";
import P from "../../components/P";

const NewsletterAbril = () => {
  return (
    <Container
      backgroundImage="/fundos_pintas/pintas_pretas.svg"
      padding="3rem 1rem"
      mobileMPadding="3rem 2rem"
      tabletPadding="6rem 8rem 6rem"
      flex
      flexDirection="column"
      alignItems="center"
    >
      <Container maxWidth="1440px">
        <Container
          backgroundImage="/produtos/salicornia100newsletter.png"
          width="66%"
          tabletWidth="calc(100% - 190px)"
          laptopWidth="calc(100% - 205px)"
          margin="0 0 2% 0"
          height="150px"
          tabletHeight="300px"
          backgroundSize="contain"
          backgroundRepeat="no-repeat"
          backgroundPosition="center"
        ></Container>
        <Heading
          level="1.1"
          textColor="#164427"
          fontWeight="700"
          textAlign="center"
        >
          Salicórnia desidratada corta o sal pela raiz
        </Heading>
        <Heading level="1.5" textColor="#164427" textAlign="center" margin="1%">
          Sabia que existem opções ao cloreto de sódio e de potássio para dar
          sabor às refeições?
        </Heading>
        <Container textAlign="justify" margin="5% 0 0">
          <P>
            Para um médico não é novidade que Portugal é dos países europeus
            onde o consumo de sal é mais elevado. Também não é novidade que a
            população portuguesa consome cerca de 7,3 g de sal*, em média por
            dia, enquanto as recomendações da Organização Mundial da Saúde (OMS)
            apontam para uma ingestão diária de 5 g de sal. E muito menos que a
            hipertensão arterial primária (também chamada por vezes de
            essencial) é aquela em que não é conhecida a causa, aproximadamente
            em 90-95% dos casos. No entanto, apesar de não se conhecer a causa,
            sabe-se que algumas situações podem facilitar o aumento da pressão
            arterial, nomeadamente o consumo excessivo de sal.
          </P>
          <P>
            Porque existem opções ao sal tradicional (cloreto de sódio) para dar
            sabor às refeições, gostaríamos de dar a conhecer a Salicórnia
            (Salicornia Ramosíssima), uma planta originária das salinas
            costeiras, desde o Ártico até ao Mediterrâneo. Em Portugal a
            Salicórnia encontra-se em locais como a Ria de Aveiro, Ria Formosa e
            outras regiões do Algarve.
          </P>
          <P>
            De caules carnudos e aroma especial, a Salicórnia tem aspeto
            semelhante aos espargos, e por isso é também designada por “espargo
            do mar”. Planta halófita, a Salicórnia produz caules suculentos de
            cerca de 40 cm e com sabor salgado. O sabor salgado nos seus caules
            é ocasionado pela absorção direta do sal do mar/solo onde se
            desenvolve. O armazenamento do sal ocorre numa glândula
            especializada nas células da planta.
          </P>
          <P>
            Colhida a partir de março/abril, quando atinge o estado adulto
            (vegetativo), podendo a sua colheita estender-se até meados de
            setembro, a Salicórnia pode apresentar valores de sódio 4 vezes
            menor que o sal tradicional, cumprindo assim aqueles que são os
            parâmetros recomendados pela Organização Mundial de Saúde.
          </P>
          <P>
            Estudos desenvolvidos ao longo dos anos, pelo Departamento de
            Biologia e pelo Departamento de Química, da Universidade de Aveiro,
            assinalam as características antioxidantes, sendo uma planta
            diurética e medicinal, rica em vitaminas e sais minerais, possuindo
            inúmeros benefícios desde propriedades anticancerígenas à prevenção
            da hipertensão arterial, desempenhando um papel importante no
            tratamento dos diabetes e da obesidade, tendo também propriedades
            antimicrobianas e anti-inflamatórias.
          </P>
          <P>
            Do ponto de vista nutricional, além da vantagem clara pela redução
            do teor de sódio na culinária, de que já falámos, a Salicórnia
            parece apresentar benefícios adicionais devido ao seu teor em
            compostos bioativos (por exemplo: esteróis, ácidos
            hidroxicinâmicos), ácidos gordos polinsaturados, fibra e minerais
            (p. ex.: ferro, potássio, cálcio) e em vitaminas como A, C e D.
          </P>
          <P>
            Reconhecida pela Sociedade Portuguesa de Hipertensão como condimento
            alternativo para diminuir ou até mesmo eliminar a ingestão de sal,
            mas sem que com isso se retire o sabor às refeições, a Salicórnia
            apresenta-se hoje no linear desidratada e pronta a condimentar 365
            dias por ano.
          </P>
        </Container>
        <Container
          width="100%"
          flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          margin="5rem 0 0"
        >
          <Container flex flexWrap="wrap" justifyContent="center">
            <Container>
              <Container
                padding="1rem 0"
                flex
                flexDirection="column"
                laptopFlexDirection="row"
                flexWrap="wrap"
                justifyContent="center"
                alignItems="center"
              >
                <Container
                  flex
                  flexDirection="column"
                  margin="5% 0 3rem 0"
                  width="100%"
                  tabletWidth="570px"
                >
                  <Container tabletPadding="1rem">
                    <Container
                      backgroundColor="#ffffffba"
                      borderRadius="0.5rem"
                      boxShadow="0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)"
                      laptopMinHeight="305px"
                      laptopLMinHeight="455px"
                    >
                      <Container
                        flex
                        flexDirection="column"
                        justifyContent="center"
                        textAlign="center"
                        height="180px"
                        backgroundColor="#70a060"
                        width="100%"
                      >
                        <Heading
                          textColor="white"
                          fontWeight="800"
                          level="1"
                          fontFamily="secondary"
                          margin="1rem 0 0"
                          textAlign="center"
                        >
                          Uma Curiosidade
                        </Heading>
                      </Container>
                      <Container
                        width="100%"
                        flex
                        justifyContent="flex-end"
                      ></Container>
                      <Container padding="2rem 2rem 1rem">
                        <Container margin="1rem 0 0">
                          <P fontSize="15px">
                            5g de sal correspondem a 1 colher de chá do sal
                            comum.
                          </P>

                          <P fontSize="15px">
                            200g de Salicórnia desidratada contém 5g de sal.
                          </P>
                        </Container>
                      </Container>
                    </Container>
                  </Container>
                </Container>
              </Container>
            </Container>
            <Container flex justifyContent="center">
              <Container
                padding="1rem 0"
                flex
                flexDirection="column"
                laptopFlexDirection="row"
                flexWrap="wrap"
                alignItems="center"
                justifyContent="center"
              >
                <Container
                  flex
                  flexDirection="column"
                  margin="5% 0 3rem 0"
                  width="100%"
                  tabletWidth="570px"
                >
                  <Container tabletPadding="1rem">
                    <Container
                      backgroundColor="#ffffffba"
                      borderRadius="0.5rem"
                      boxShadow="0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)"
                      laptopMinHeight="405px"
                      laptopLMinHeight="400px"
                    >
                      <Container
                        flex
                        flexDirection="column"
                        justifyContent="center"
                        textAlign="center"
                        height="180px"
                        backgroundColor="#bb3e4b"
                        width="100%"
                      >
                        <Heading
                          textColor="white"
                          fontWeight="800"
                          level="1"
                          fontFamily="secondary"
                          margin="1rem 0 0"
                          textAlign="center"
                        >
                          Informação nutricional (100g)
                        </Heading>
                      </Container>
                      <Container
                        width="100%"
                        flex
                        justifyContent="flex-end"
                      ></Container>
                      <Container padding="2rem 2rem 1rem">
                        <Container margin="1rem 0 0">
                          <P fontSize="15px">
                            <strong>Energia:</strong> 680 kJ/163 kcal
                          </P>

                          <P fontSize="15px">
                            <strong>Lípidos:</strong> 0,968 g, dos quais
                            saturados 0,447{" "}
                          </P>
                          <P fontSize="15px">
                            <strong>Hidratos de Carbono:</strong> 13 g, dos
                            quais açúcares (da planta) 2,88
                          </P>
                          <P fontSize="15px">
                            <strong>Proteínas:</strong> 10,05 g
                          </P>
                          <P fontSize="15px">
                            <strong>Sal:</strong> 28,6 g
                          </P>
                        </Container>
                      </Container>
                    </Container>
                  </Container>
                </Container>
              </Container>
            </Container>
          </Container>
          <Container flex justifyContent="center">
            <Container
              padding="1rem 0"
              flex
              flexDirection="column"
              laptopFlexDirection="row"
              flexWrap="wrap"
              alignItems="center"
              justifyContent="center"
            >
              <Container
                flex
                flexDirection="column"
                margin="5% 0 3rem 0"
                width="100%"
                tabletWidth="570px"
              >
                <Container tabletPadding="1rem">
                  <Container
                    backgroundColor="#ffffffba"
                    borderRadius="0.5rem"
                    boxShadow="0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)"
                    laptopMinHeight="505px"
                    laptopLMinHeight="520px"
                  >
                    <Container
                      flex
                      flexDirection="column"
                      justifyContent="center"
                      textAlign="center"
                      height="180px"
                      backgroundColor="#347584"
                      width="100%"
                    >
                      <Heading
                        textColor="white"
                        fontWeight="800"
                        level="1"
                        fontFamily="secondary"
                        margin="1rem 0 0"
                        textAlign="center"
                      >
                        Como utilizar Salicórnia
                      </Heading>
                    </Container>
                    <Container
                      width="100%"
                      flex
                      justifyContent="flex-end"
                    ></Container>
                    <Container padding="2rem 2rem 1rem">
                      <Container margin="1rem 0 0">
                        <P fontSize="15px">
                          Para utilizar a Salicórnia desidratada sugerimos que
                          espalhe este condimento uniformemente sobre por
                          exemplo a carne, o tofu, o seitan ou o peixe de ambos
                          os lados e de maneira a cobrir a totalidade da
                          superfície. Pode também ser utilizado em marinadas,
                          estufados, caldeiradas e outros pratos de panela. Nas
                          massas a Salicórnia deverá ser adicionada no final, e
                          não no início como faria com o sal.
                        </P>

                        <P fontSize="15px">
                          Como não dilui como o sal, podemos corrigir o tempero
                          com alguma tranquilidade. Para saber mais sobre a
                          utilização da Salicórnia visite{" "}
                          <Link
                            to="/"
                            style={{ textDecoration: "none", color: "black" }}
                          >
                            <strong>aqui.</strong>
                          </Link>
                        </P>
                      </Container>
                    </Container>
                  </Container>
                </Container>
              </Container>
            </Container>
          </Container>
        </Container>
      </Container>
    </Container>
  );
};

export default NewsletterAbril;
