import { createGlobalStyle } from "styled-components";

import BobbyJones from "./fonts/BobbyJones/bobby-jones-soft-regular.woff";

import Open_SansBold from "./fonts/Open_Sans/OpenSans-Bold.woff";
import Open_SansExtra from "./fonts/Open_Sans/OpenSans-ExtraBold.ttf";
import Open_SansRegular from "./fonts/Open_Sans/OpenSans-Regular.woff";

const GlobalStyles = createGlobalStyle`

 @font-face {
    font-family: Bobby Jones;
        src: local('Bobby Jones'), local('Bobby Jones'),
        url(${BobbyJones}) format('woff');
    }

 @font-face {
    font-family: 'Open Sans';
        src: local('Open Sans'), local('Open Sans'),
        url(${Open_SansBold}) format('woff');
        font-weight: 800;
        font-style: normal;
    }
    @font-face {
    font-family: 'Open Sans';
        src: local('Open Sans'), local('Open Sans'),
        url(${Open_SansExtra}) format('woff');
        font-weight: 900;
        font-style: normal;
    }
    @font-face {
    font-family: 'Open Sans';
        src: local('Open Sans'), local('Open Sans'),
        url(${Open_SansRegular}) format('woff');
        font-weight: 500;
        font-style: normal;
    }

  body {
    background-color: ${(props) => props.theme.main.colors.secondary};
    font-family: 'Open Sans', sans-serif;
  }
  p {
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 1.5;
  }

  #root {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 0;
    margin: 0;
    overflow: hidden;
  }
`;

export default GlobalStyles;
