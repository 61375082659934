const menuItems = [
  {
    name: "Sobre Salicórnia",
    path: "/sobre-salicornia",
    id: "sobre-salicornia",
  },
  {
    name: "Informação Nutricional",
    path: "/informacao-nutricional",
    id: "informacao-nutricional",
  },
  {
    name: "Salicórnia Qampo",
    path: "/salicornia-qampo",
    id: "salicornia-qampo",
  },
  {
    name: "Receitas",
    path: "/receitas",
    id: "receitas",
  },
  {
    name: "Salicórnia na Imprensa",
    path: "/clipping-slider",
    id: "clipping-slider",
  },
  {
    name: "Onde Comprar",
    path: "/ondecomprar",
    id: "ondecomprar",
  },
  // {
  //   name: "Testemunhos",
  //   path: "/testemunhos",
  //   id: "testemunhos",
  // },
  {
    name: "Contatos",
    path: "/contatos",
    id: "contactos",
  },
];

export default menuItems;
