// Import Components
import { useEffect } from "react";
import Container from "../../../components/Container";
import Slider from "react-slick";
import ClippingData from "./data";
import A from "../../../components/A";
import P from "../../../components/P";
import Heading from "../../../components/Heading";

//iniciar a variável fora das condiciionasi
//substring (0, 101)

//condicional com lenth
//se >100
//substring
//else
//spread à data
//ternary operator se existir conteúdo novo, então add

const ClippingSlider = () => {
  let data = ClippingData;

  data.forEach((element, index) => {
    let paragraph;
    if (element.paragraph.length > 200) {
      paragraph = element.paragraph.substring(0, 200) + " " + "[...]";
      data[index] = { ...element, paragraph: paragraph };
    }
  });

  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 600,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Container
      id="clipping-slider"
      backgroundImage="/fundos_pintas/pintas_pretas.svg"
      backgroundRepeat="repeat"
      backgroundSize="auto"
      flex
      maxWidth="100%"
    >
      <Container margin="3rem 0 6rem" width="100%" flex justifyContent="center">
        <Container
          desktopWidth="30%"
          laptopLWidth="65%"
          laptopWidth="80%"
          tabletWidth="90%"
          width="100%"
        >
          <Container flex className="heading" margin="2rem 0">
            <Heading
              level="1"
              className="title_testemunhos"
              fontWeight="800"
              laptopLevel="0.8"
              textColor="#7C9A64"
              textAlign="center"
              margin="2rem"
            >
              Salicórnia na Imprensa
            </Heading>
          </Container>
          <Container width="100%">
            <Slider {...settings} className="slider-slick-div">
              {data.map((element, i) => {
                return (
                  <Container
                    className="slider-div"
                    backgroundColor="#ffffffba"
                    borderRadius="0.5rem"
                    boxShadow="0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)"
                    position="relative"
                    tabletMinHeight="650px"
                    laptopMinHeight="600px"
                    laptopLMinHeight="600px"
                    key={i}
                  >
                    <Container flex flexDirection="column" alignItems="center">
                      <Container height="30%" tabletHeight="30%" width="100%">
                        <img
                          className="slick-slider-image"
                          src={element.image}
                        ></img>
                      </Container>
                      <Container
                        margin="2rem 0"
                        flex
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        padding=" 0 1rem"
                        height="60%"
                      >
                        {element.title ? (
                          <Heading
                            level="1.5"
                            textColor="#70a160"
                            fontWeight="600"
                          >
                            {element.title}
                          </Heading>
                        ) : null}
                        {element.author ? (
                          <Heading
                            level="1.4"
                            textColor="#70a160"
                            fontWeight="600"
                          >
                            {element.author}
                          </Heading>
                        ) : null}
                        {!element.author ? (
                          <Container>
                            <P textAlign="justify">{element.paragraph}</P>
                          </Container>
                        ) : (
                          <Container>
                            <P textAlign="justify">{element.paragraph}</P>
                          </Container>
                        )}
                        <Container margin="2rem 0 0" minHeight>
                          <A href={element.url} target="_blank">
                            <button className="clipping-button">
                              Saiba mais
                            </button>
                          </A>
                        </Container>
                      </Container>
                    </Container>
                  </Container>
                );
              })}
            </Slider>
          </Container>
        </Container>
      </Container>
    </Container>
  );
};

export default ClippingSlider;
