//Import React Components
import React from "react";

// Import Components
import Container from "../../../components/Container";
import Heading from "../../../components/Heading";
import P from "../../../components/P";
import SVG from "../../../components/SVG";

import ProducaoAgricola from "../../../util/SVG/salicornia-campo-icons/producao-agricola";
import Secagem from "../../../util/SVG/salicornia-campo-icons/secagem";
import Processamento from "../../../util/SVG/salicornia-campo-icons/processamento";
import Armazenamento from "../../../util/SVG/salicornia-campo-icons/armazenamento";

const IntroProducts = () => {
  return (
    <Container
      id="salicornia-qampo"
      backgroundImage="/fundos_pintas/pintas_pretas.svg"
      borderBottom="2px dashed #164427"
      position="relative"
    >
      <Container
        laptopPadding="4rem 5rem"
        laptopLPadding="4rem 12.5rem 0"
        padding="3rem 3rem"
        maxWidth="1000px"
        margin="0 auto"
        position="relative"
      >
        <Container
          position="absolute"
          width="100px"
          laptopWidth="150px"
          laptopLWidth="150px"
          tabletWidth="120px"
          top="-39px"
          laptopTop="-72px"
          laptopLTop="-75px"
          tabletTop="-58px"
          tabletLeft="60%"
          laptopLeft="65%"
          laptopLLeft="55%"
          left="70%"
        >
          <img width="100%" src="/page_elements/badge_75.png" alt="75% badge" />
        </Container>
        <Container laptopMargin="0 0 2rem 0" width="fit-content">
          <Heading
            fontWeight="800"
            level=".7"
            laptopLevel=".8"
            textColor="#7C9A64"
          >
            Salicórnia
          </Heading>
          <Container flex justifyContent="space-between">
            <Heading
              fontWeight="800"
              level=".7"
              laptopLevel=".8"
              textColor="#7C9A64"
              width="fit-content"
            >
              qampo
            </Heading>
            <Container laptopHeight="70px" height="50px">
              <img height="100%" src="/page_elements/elemento.png" alt="ramo" />
            </Container>
          </Container>
        </Container>
        <Container
          padding="1rem 0"
          flex
          flexDirection="column"
          laptopFlexDirection="row"
          flexWrap="wrap"
          justifyContent="space-between"
          alignItems="center"
        >
          <Container
            flex
            flexDirection="column"
            margin="5% 0 3rem 0"
            width="100%"
            tabletWidth="60%"
            laptopWidth="45%"
          >
            <Container tabletPadding="1rem">
              <Container
                backgroundColor="#ffffffba"
                borderRadius="0.5rem"
                boxShadow="0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)"
                laptopMinHeight="705px"
                laptopLMinHeight="680px"
              >
                <Container
                  flex
                  flexDirection="column"
                  justifyContent="center"
                  textAlign="center"
                  height="180px"
                  backgroundImage="/fundo_rasgado/azul_rasgado.png"
                  width="100%"
                >
                  <Heading
                    textColor="white"
                    fontWeight="800"
                    level="1"
                    fontFamily="secondary"
                    margin="1rem 0 0"
                    textAlign="center"
                  >
                    Colheita
                  </Heading>
                  <Heading
                    textColor="white"
                    fontWeight="800"
                    level="1.5"
                    fontFamily="secondary"
                    textAlign="center"
                  >
                    (Produção Agrícola)
                  </Heading>
                </Container>
                <Container width="100%" flex justifyContent="flex-end">
                  <SVG
                    svg={<ProducaoAgricola />}
                    width="90"
                    height="90"
                    margin="-50px 35px 0 0"
                    tabletMargin="-50px 50px 0 0"
                  />
                </Container>
                <Container padding="2rem 2rem 1rem">
                  <Container margin="1rem 0 0">
                    <P fontSize="15px">
                      O processo produtivo e de colheita junta à produção
                      biológica alguns princípios de agricultura de precisão. A
                      colheita é feita com recurso a máquina semiautomática e a
                      assemblagem efetuada em caixa de plástico, com um peso
                      individual estabelecido de Salicórnia de 2 kg.
                    </P>

                    <P fontSize="15px">
                      O transporte é realizado em veículo refrigerado,
                      diretamente de Aveiro, até às instalações da Novadelta, em
                      Campo Maior. À chegada, mas ainda dentro do veículo, cada
                      lote é avaliado ao nível da temperatura e da humidade.
                      Posteriormente cada lote é sujeito a mais sete avaliações.
                    </P>
                  </Container>
                </Container>
              </Container>
            </Container>
          </Container>
          <Container
            flex
            flexDirection="column"
            margin="5% 0 3rem 0"
            width="100%"
            tabletWidth="60%"
            laptopWidth="45%"
          >
            <Container tabletPadding="1rem">
              <Container
                backgroundColor="#ffffffba"
                borderRadius="0.5rem"
                boxShadow="0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)"
                laptopMinHeight="705px"
                laptopLMinHeight="680px"
                position="relative"
              >
                <Container
                  flex
                  flexDirection="column"
                  justifyContent="center"
                  textAlign="center"
                  height="180px"
                  backgroundImage="/fundo_rasgado/amarelo_rasgado.png"
                  backgroundSize="cover"
                  margin-bottom="1rem"
                >
                  <Heading
                    textColor="white"
                    fontWeight="800"
                    level="1"
                    fontFamily="secondary"
                    margin="1rem 0"
                    textAlign="center"
                  >
                    
                    Secagem
                  </Heading>
                </Container>
                <Container width="100%" flex justifyContent="flex-end">
                  <SVG
                    svg={<Secagem />}
                    width="90"
                    height="90"
                    margin="-50px 35px 0 0"
                    tabletMargin="-50px 50px 0 0"
                  />
                </Container>
                <Container padding="2rem 2rem 1rem">
                  <Container margin="1rem 0 0">
                    <P fontSize="15px">
                      O processo de conservação natural passa pela desidratação
                      da planta, através de um sistema de ventilação híbrido,
                      que retira de forma controlada e acelerada toda a humidade
                      excessiva de planta.
                    </P>
                    <P fontSize="15px">
                      Com três unidades de secagem, a que corresponde uma
                      capacidade máxima estimada em 4.5 toneladas a cada 84
                      horas, o produto final mantém todas as propriedades
                      organoléticas, a intensidade sensorial e a cor original da
                      variedade. A temperatura e a humidade são aferidas a cada
                      24 horas.
                    </P>
                  </Container>
                </Container>
              </Container>
            </Container>
          </Container>
          <Container
            flex
            flexDirection="column"
            margin="5% 0 3rem 0"
            width="100%"
            tabletWidth="60%"
            laptopWidth="45%"
          >
            <Container tabletPadding="1rem">
              <Container
                backgroundColor="#ffffffba"
                borderRadius="0.5rem"
                boxShadow="0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)"
                laptopMinHeight="700px"
                laptopLMinHeight="625px"
                position="relative"
              >
                <Container
                  flex
                  flexDirection="column"
                  justifyContent="center"
                  textAlign="center"
                  height="180px"
                  backgroundImage="/fundo_rasgado/vermelho_rasgado.png"
                  backgroundSize="cover"
                  margin-bottom="1rem"
                >
                  <Heading
                    textColor="white"
                    fontWeight="800"
                    level="1"
                    fontFamily="secondary"
                    margin="1rem 0"
                    textAlign="center"
                  >
                    
                    Processamento
                  </Heading>
                </Container>
                <Container width="100%" flex justifyContent="flex-end">
                  <SVG
                    svg={<Processamento />}
                    width="90"
                    height="90"
                    margin="-50px 35px 0 0"
                    tabletMargin="-50px 50px 0 0"
                  />
                </Container>
                <Container padding="2rem 2rem 1rem">
                  <Container margin="1rem 0 0">
                    <P fontSize="15px">
                      A moagem é efetuada com recurso a moinho de martelos
                      ventilado, onde a Salicórnia desidratada é cortada, moída,
                      calibrada e limpa.
                    </P>
                    <P fontSize="15px">
                      Graças a este processo evita-se a fricção das partículas e
                      a perda das propriedades organoléticas que caracterizam a
                      Salicórnia.
                    </P>
                  </Container>
                </Container>
              </Container>
            </Container>
          </Container>
          <Container
            flex
            flexDirection="column"
            margin="5% 0 3rem 0"
            width="100%"
            tabletWidth="60%"
            laptopWidth="45%"
          >
            <Container tabletPadding="1rem">
              <Container
                backgroundColor="#ffffffba"
                borderRadius="0.5rem"
                boxShadow="0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)"
                laptopMinHeight="700px"
                laptopLMinHeight="625px"
                position="relative"
              >
                <Container
                  textAlign="center"
                  padding="3rem 0 4rem"
                  margin-bottom="1rem"
                  backgroundImage="/fundo_rasgado/verde_rasgado.png"
                  backgroundSize="cover"
                >
                  <Heading
                    textColor="white"
                    fontWeight="800"
                    level="1"
                    fontFamily="secondary"
                    margin="1rem 0"
                    textAlign="center"
                  >
                    
                    Armazenamento
                  </Heading>
                </Container>
                <Container width="100%" flex justifyContent="flex-end">
                  <SVG
                    svg={<Armazenamento />}
                    width="90"
                    height="90"
                    margin="-50px 35px 0 0"
                    tabletMargin="-50px 50px 0 0"
                  />
                </Container>
                <Container padding="2rem 2rem 1rem">
                  <Container margin="1rem 0 0">
                    <P fontSize="15px">
                      O armazenamento do produto final é realizado em ambiente
                      controlado, onde os índices de humidade são mantidos
                      abaixo de 30%, para evitar o desenvolvimento de bolores e
                      fungos.
                    </P>
                    <P fontSize="15px">
                      A Naturdelta é, em Portugal, o operador com maior
                      capacidade instalada de secagem e processamento, além de
                      estar dotada de equipamentos altamente eficientes em
                      processos contínuos de separação, limpeza e calibração de
                      Plantas Aromáticas e Medicinais (PAM).
                    </P>
                  </Container>
                </Container>
              </Container>
            </Container>
          </Container>
        </Container>
        <Container margin="2rem 0" display="block" zIndex="-20">
          <img width="100%" src="/page_elements/paisagem.jpeg" />
        </Container>
      </Container>
      <Container
        position="absolute"
        bottom="0"
        laptopLeft="-6.5rem"
        laptopLLeft="-11.5rem"
        left="-5.5rem"
        desktopLeft="0"
        laptopLWidth="40%"
        width="30%"
        desktopWidth="20%"
        maxWidth="800px"
        zIndex="10005"
        margin="0 0 -5px 0"
      >
        <img
          width="80%"
          display="block"
          src="/page_elements/salicornia-ramo-vertical.png"
        />
      </Container>
    </Container>
  );
};

export default IntroProducts;
