import React, { useState } from "react";
import axios from "axios";

//Import Form style
import "../email/index.css";

// Import Components
import Container from "../../../components/Container";
import Heading from "../../../components/Heading";
import P from "../../../components/P";

function Email() {
  const [from, setFrom] = useState("");
  const [subject, setSubject] = useState("");
  const [text, setText] = useState("");
  const [mail, setMail] = useState("no-mail");

  const submitForm = (event) => {
    event.preventDefault();
    const email = {
      from,
      subject,
      text,
    };
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}/form`, email)
      .then((response) => {
        if (response.data.status === 202) {
          setMail("sent");
        } else {
          setMail("error");
        }
      });
  };
  return (
    <Container
      backgroundImage="/fundos_pintas/pintas_pretas.svg"
      backgroundRepeat="repeat"
      backgroundSize="auto"
      className="Email"
      id="contactos"
    >
      <Container
        padding="3rem 3rem"
        laptopPadding="6rem 5rem"
        maxWidth="1000px"
        margin="0 auto"
      >
        <Container flex className="heading" alignItems="center">
          <Heading
            level="1"
            className="title_testemunhos"
            fontWeight="800"
            laptopLevel="0.8"
            textColor="#7C9A64"
            textAlign="center"
          >
            Contacte-nos
          </Heading>
        </Container>
        <Container
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "2rem",
          }}
        >
          <Container laptopWidth="60%" tabletWidth="80%" width="90%">
            <form
              style={{ display: "flex", flexDirection: "column" }}
              onSubmit={submitForm}
            >
              <input
                value={from}
                onChange={(e) => setFrom(e.target.value)}
                placeholder="De:"
                type="email"
                name="To_Email"
                required
                className="inputs"
              />
              <input
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                placeholder="Assunto:"
                type="text"
                name="Subject"
                required
                className="inputs"
              />
              <textarea
                value={text}
                onChange={(e) => setText(e.target.value)}
                placeholder="Conteúdo:"
                type="text"
                name="Text"
                required
                className="inputs text_input"
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "8px 0",
                }}
              >
                <button className="button" type="submit">
                  Submeter
                </button>
              </div>
            </form>
            {mail === "sent" ? (
              <Container
                flex
                justifyContent="center"
                border="3px solid #7C9A64"
                margin="5%"
              >
                <P color="#7C9A64" fontWeight="bold" margin="0">
                  {" "}
                  Email enviado com sucesso.
                </P>
              </Container>
            ) : null}
            {mail === "error" ? (
              <Container
                flex
                justifyContent="center"
                border="3px solid rgb(173 49 49)"
                margin="5%"
              >
                <P color="rgb(173 49 49)" fontWeight="bold" margin="0">
                  {" "}
                  Erro no envio do email. Tente novamente mais tarde.
                </P>
              </Container>
            ) : null}
          </Container>
        </Container>
      </Container>
    </Container>
  );
}

export default Email;
