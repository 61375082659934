const FacebookIcon = (props) => {
    return (
      <svg
        id="FacebookIcon"
        data-name="Layer"
        xmlns="http://www.w3.org/2000/svg"
        width="26" height="26" viewBox="0 0 24 24"
        fill="none" stroke="transparent" strokeWidth="1"
        strokeLinecap="round" strokeLinejoin="round"
        className="feather feather-facebook"
        >
        <title>Facebook_Icon</title>
        <g>
        <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
        </g>
      </svg>
    );
  };
  
  export default FacebookIcon;
  