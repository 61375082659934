import React from "react";

import { Link } from "react-router-dom";
import Container from "../../components/Container";
import Heading from "../../components/Heading";
import P from "../../components/P";

const NewsletterMaio = () => {
  return (
    <Container
      backgroundImage="/fundos_pintas/pintas_pretas.svg"
      padding="3rem 1rem"
      mobileMPadding="3rem 2rem"
      tabletPadding="6rem 8rem 6rem"
      flex
      flexDirection="column"
      alignItems="center"
    >
      <Container maxWidth="1440px">
        <Container
          backgroundImage="/newsletter/newslettermaio.png"
          margin="0 0 2% 0"
          height="150px"
          tabletHeight="300px"
          backgroundSize="contain"
          backgroundRepeat="no-repeat"
          backgroundPosition="center"
        ></Container>
        <Heading
          level="1.1"
          textColor="#164427"
          fontWeight="700"
          textAlign="center"
        >
          Vamos cortar o sal pela raiz?
        </Heading>
        <Heading level="1.5" textColor="#164427" textAlign="center" margin="1%">
          Já ouviu falar de salicórnia? Sabia que esta planta pode ter até menos
          75% de sódio do que o sal comum?
        </Heading>
        <Container textAlign="justify" margin="5% 0 0">
          <P>
            Nascida em ambiente com alta concentração de sal, a salicórnia, que
            aprisiona na raiz os materiais pesados, consegue absorver uma grande
            quantidade de minerais do solo, pelo que a podemos caracterizar como
            sendo uma planta muito rica em minerais, principalmente sódio. No
            entanto, quando comparamos igual quantidades de biomassa de
            salicórnia desidratada e sal de cozinha apuramos que a biomassa
            desidratada de salicórnia pode ter até menos 75% de sódio do que o
            sal comum.
          </P>
          <P>
            A Universidade de Aveiro, que tem sido uma das instituições líder
            nas{" "}
            <a
              href="https://visao.sapo.pt/visaosaude/2022-04-15-salicornia-o-que-e-e-os-beneficios-deste-substituto-saudavel-do-sal/"
              target="_blank"
              style={{ textDecoration: "underline", color: "inherit" }}
            >
              pesquisas sobre a salicórnia
            </a>{" "}
            destaca as “características antioxidantes” da salicórnia, “uma
            planta diurética e medicinal, rica em vitaminas e sais minerais com
            inúmeros benefícios que vão desde propriedades anticancerígenas à
            prevenção da hipertensão arterial, desempenhando também um papel
            importante no tratamento dos diabetes e da obesidade e contando com
            propriedades antimicrobianas e anti-inflamatórias”.
          </P>
          <P>
            Ou seja, da substituição do sal por salicórnia desidratada
            conseguimos um aporte muito significativo de muitos outros elementos
            que podem prevenir a hipertensão, entre outras doenças.
          </P>
          <P>
            Reduzir o consumo de sal é por isso um caminho que tem de ser
            efetuado com a máxima urgência, até porque existem estudos que nos
            mostram que a redução de sal melhora a longevidade, diminui as
            doenças cardiovasculares, a pressão arterial e consequentemente a
            necessidade de toma de medicamentos para reduzir a pressão arterial.
          </P>
          <P>
            No mercado existem opções para hipertensos que apresentam uma
            mistura de cloreto de sódio e de potássio, por exemplo o vulgarmente
            chamado sal para hipertensos. No entanto, há doentes com disfunção
            renal que não podem fazer suplementos de potássio. Para estes a
            salicórnia passa a ser, no cardápio das alternativas ao sal, uma
            opção importante.
          </P>
          <P>
            Reconhecida pela Sociedade Portuguesa de Hipertensão como condimento
            alternativo para diminuir ou até mesmo eliminar a ingestão de sal,
            mas sem que com isso se retire o sabor às refeições, a Salicórnia
            apresenta-se hoje desidratada e{" "}
            <a
              href="https://salicornia.qampo.pt/"
              target="_blank"
              style={{ textDecoration: "underline", color: "inherit" }}
            >
              pronta a condimentar
            </a>{" "}
            365 dias por ano.
          </P>
        </Container>
        <Container
          width="100%"
          flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          margin="5rem 0 0"
        >
          <Container flex flexWrap="wrap" justifyContent="center">
            <Container>
              <Container
                padding="1rem 0"
                flex
                flexDirection="column"
                laptopFlexDirection="row"
                flexWrap="wrap"
                justifyContent="center"
                alignItems="center"
              >
                <Container
                  flex
                  flexDirection="column"
                  margin="5% 0 3rem 0"
                  width="100%"
                  tabletWidth="570px"
                >
                  <Container tabletPadding="1rem">
                    <Container
                      backgroundColor="#ffffffba"
                      borderRadius="0.5rem"
                      boxShadow="0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)"
                      laptopMinHeight="305px"
                      laptopLMinHeight="455px"
                    >
                      <Container
                        flex
                        flexDirection="column"
                        justifyContent="center"
                        textAlign="center"
                        height="180px"
                        backgroundColor="#70a060"
                        width="100%"
                      >
                        <Heading
                          textColor="white"
                          fontWeight="800"
                          level="1"
                          fontFamily="secondary"
                          margin="1rem 0 0"
                          textAlign="center"
                        >
                          Uma Curiosidade
                        </Heading>
                      </Container>
                      <Container
                        width="100%"
                        flex
                        justifyContent="flex-end"
                      ></Container>
                      <Container padding="2rem 2rem 1rem">
                        <Container margin="1rem 0 0">
                          <P fontSize="15px">
                            5g de sal correspondem a 1 colher de chá do sal
                            comum.
                          </P>

                          <P fontSize="15px">
                            200g de Salicórnia desidratada contém 5g de sal.
                          </P>
                        </Container>
                      </Container>
                    </Container>
                  </Container>
                </Container>
              </Container>
            </Container>
            <Container flex justifyContent="center">
              <Container
                padding="1rem 0"
                flex
                flexDirection="column"
                laptopFlexDirection="row"
                flexWrap="wrap"
                alignItems="center"
                justifyContent="center"
              >
                <Container
                  flex
                  flexDirection="column"
                  margin="5% 0 3rem 0"
                  width="100%"
                  tabletWidth="570px"
                >
                  <Container tabletPadding="1rem">
                    <Container
                      backgroundColor="#ffffffba"
                      borderRadius="0.5rem"
                      boxShadow="0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)"
                      laptopMinHeight="405px"
                      laptopLMinHeight="400px"
                    >
                      <Container
                        flex
                        flexDirection="column"
                        justifyContent="center"
                        textAlign="center"
                        height="180px"
                        backgroundColor="#bb3e4b"
                        width="100%"
                      >
                        <Heading
                          textColor="white"
                          fontWeight="800"
                          level="1"
                          fontFamily="secondary"
                          margin="1rem 0 0"
                          textAlign="center"
                        >
                          Informação nutricional (100g)
                        </Heading>
                      </Container>
                      <Container
                        width="100%"
                        flex
                        justifyContent="flex-end"
                      ></Container>
                      <Container padding="2rem 2rem 1rem">
                        <Container margin="1rem 0 0">
                          <P fontSize="15px">
                            <strong>Energia:</strong> 680 kJ/163 kcal
                          </P>

                          <P fontSize="15px">
                            <strong>Lípidos:</strong> 0,968 g, dos quais
                            saturados 0,447{" "}
                          </P>
                          <P fontSize="15px">
                            <strong>Hidratos de Carbono:</strong> 13 g, dos
                            quais açúcares (da planta) 2,88
                          </P>
                          <P fontSize="15px">
                            <strong>Proteínas:</strong> 10,05 g
                          </P>
                          <P fontSize="15px">
                            <strong>Sal:</strong> 28,6 g
                          </P>
                        </Container>
                      </Container>
                    </Container>
                  </Container>
                </Container>
              </Container>
            </Container>
          </Container>
          <Container flex justifyContent="center">
            <Container
              padding="1rem 0"
              flex
              flexDirection="column"
              laptopFlexDirection="row"
              flexWrap="wrap"
              alignItems="center"
              justifyContent="center"
            >
              <Container
                flex
                flexDirection="column"
                margin="5% 0 3rem 0"
                width="100%"
                tabletWidth="570px"
              >
                <Container tabletPadding="1rem">
                  <Container
                    backgroundColor="#ffffffba"
                    borderRadius="0.5rem"
                    boxShadow="0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)"
                    laptopMinHeight="505px"
                    laptopLMinHeight="520px"
                  >
                    <Container
                      flex
                      flexDirection="column"
                      justifyContent="center"
                      textAlign="center"
                      height="180px"
                      backgroundColor="#347584"
                      width="100%"
                    >
                      <Heading
                        textColor="white"
                        fontWeight="800"
                        level="1"
                        fontFamily="secondary"
                        margin="1rem 0 0"
                        textAlign="center"
                      >
                        Como utilizar Salicórnia
                      </Heading>
                    </Container>
                    <Container
                      width="100%"
                      flex
                      justifyContent="flex-end"
                    ></Container>
                    <Container padding="2rem 2rem 1rem">
                      <Container margin="1rem 0 0">
                        <P fontSize="15px">
                          Para utilizar a Salicórnia desidratada sugerimos que
                          espalhe este condimento uniformemente sobre por
                          exemplo a carne, o tofu, o seitan ou o peixe de ambos
                          os lados e de maneira a cobrir a totalidade da
                          superfície. Pode também ser utilizado em marinadas,
                          estufados, caldeiradas e outros pratos de panela. Nas
                          massas a Salicórnia deverá ser adicionada no final, e
                          não no início como faria com o sal.
                        </P>

                        <P fontSize="15px">
                          Como não dilui como o sal, podemos corrigir o tempero
                          com alguma tranquilidade. Para saber mais sobre a
                          utilização da Salicórnia visite{" "}
                          <Link
                            to="/"
                            style={{ textDecoration: "none", color: "black" }}
                          >
                            <strong>aqui.</strong>
                          </Link>
                        </P>
                      </Container>
                    </Container>
                  </Container>
                </Container>
              </Container>
            </Container>
          </Container>
        </Container>
      </Container>
    </Container>
  );
};

export default NewsletterMaio;
