const theme = {
  main: {
    colors: {
      primary: "",
      secondary: "",
      tertiary: "",
    },
    sizes: {},
    fonts: {
      primary: "'Open Sans', sans-serif;",
      secondary: "'Bobby Jones', sans-serif;",
    },
  },
};

const responsiveSizes = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "2560px",
};

const device = {
  mobileS: `(min-width: ${responsiveSizes.mobileS})`,
  mobileM: `(min-width: ${responsiveSizes.mobileM})`,
  mobileL: `(min-width: ${responsiveSizes.mobileL})`,
  tablet: `(min-width: ${responsiveSizes.tablet})`,
  laptop: `(min-width: ${responsiveSizes.laptop})`,
  laptopL: `(min-width: ${responsiveSizes.laptopL})`,
  desktop: `(min-width: ${responsiveSizes.desktop})`,
  desktopL: `(min-width: ${responsiveSizes.desktop})`,
};

export { theme, device };
