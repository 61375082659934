import React from "react";

import { Link } from "react-router-dom";
import Container from "../../components/Container";
import Heading from "../../components/Heading";
import P from "../../components/P";

const NovaNewsletter = () => {
  return (
    <Container
      backgroundImage="/fundos_pintas/pintas_pretas.svg"
      flex
      flexDirection="column"
      alignItems="center"
    >
      <Container maxWidth="1440px">
        <Container
          backgroundImage="/newsletter/novanewsletter.png"
          margin="0 0 2% 0"
          height="150px"
          tabletHeight="400px"
          backgroundSize="cover"
          backgroundRepeat="no-repeat"
          backgroundPosition="center"
        ></Container>
        <Container
          padding="3rem 1rem"
          mobileMPadding="3rem 2rem"
          tabletPadding="6rem 8rem 6rem"
        >
          <Heading
            level="0.7"
            textColor="#70a060"
            fontWeight="700"
            textAlign="center"
            fontFamily="secondary"
          >
            Como diminuir ou eliminar a ingestão de sal
          </Heading>
          <Container textAlign="justify" margin="5% 0 0">
            <P>
              Alimento extremamente versátil,excelente para temperar carne,
              peixe, marisco e saladas, podendo ser usada fresca (como
              acompanhamento) ou como especiaria, a salicórnia tem vindo a ser
              adotada por “chefs” de cozinha em todo o mundo.
            </P>
            <P>
              Por cá, a salicórnia tem uma história interessante que a coloca
              nas caravelas dos descobrimentos, onde era utilizada pelos
              marinheiros portugueses na luta contra o escorbuto. No século
              XVIII, era incorporada no fabrico de soda por incineração. Durante
              a Segunda Guerra Mundial vendia-se como “espargo do
              mar”(conservada em gelo) e chegou a substituir o feijão-verde. Já
              foi utilizada para desengordurar estofos e na indústria do sabão e
              do vidro. No entanto,a medicina tradicional sempre utilizou
              salicórnia para, por exemplo, curar constipação, situações de
              obesidade e diabetes.
            </P>
            <P>
              De sabor salgado suave, esta especiaria permite preparar temperar
              o nosso prato com menos 75% de sódio,mantendo o seu agradável
              sabor e evitando, assim,os excessos.
            </P>
            <Container
              flex
              laptopFlexDirection="row"
              flexDirection="column"
              justifyContent="space-between"
            >
              <Container laptopWidth="60%" width="100%">
                <P>
                  Nutricionalmente a salicórnia é uma fonte de potássio, cálcio,
                  manganês, iodo, ferro, vitaminas A, C e do complexo B, além de
                  produzir compostos que são benéficos para a saúde humana e dos
                  quais destacamos os polifenóis, esteroides e serotonina.
                </P>
                <P>
                  A Universidade de Aveiro apresenta a salicórnia como “uma
                  planta diurética e medicinal,rica em vitaminas e sais
                  minerais, com inúmeros benefícios que vão desde propriedades
                  anticancerígenas à prevenção da hipertensão arterial,
                  desempenhando também um papel importante no tratamento dos
                  diabetes e da obesidade e contando com propriedades
                  antimicrobianas e anti-inflamatórias”.
                </P>
                <P>
                  Ou seja, da substituição do sal por salicórnia desidratada
                  conseguimos um aporte muito significativo de muitos outros
                  elementos que podem prevenir a hipertensão,entre outras
                  doenças.
                </P>
              </Container>
              <Container
                maxWidth="calc(100% - 2rem)"
                tabletMaxWidth="0"
                margin="5% 0 0 0"
                backgroundColor="#70a060"
                laptopWidth="30%"
                width="100%"
                padding="1rem"
              >
                <Container
                  backgroundImage="/produtos/salicornia100newsletter.png"
                  height="150px"
                  backgroundSize="contain"
                  backgroundPosition="center"
                  backgroundRepeat="no-repeat"
                  margin="0 0 0 -8rem"
                  laptopMargin="0 0 0 -8rem"
                  laptopLMargin="0 0 0 -8rem"
                ></Container>
                <Heading
                  textColor="white"
                  fontWeight="800"
                  level="2"
                  fontFamily="secondary"
                  margin="1rem 0 0"
                  textAlign="center"
                >
                  Informação nutricional (100g)
                </Heading>
                <Container padding="2rem 2rem 1rem">
                  <Container margin="1rem 0 0">
                    <P color="white" fontSize="12px">
                      <strong>Energia:</strong> 680 kJ/163 kcal
                    </P>

                    <P color="white" fontSize="12px">
                      <strong>Lípidos:</strong> 0,968 g, dos quais saturados
                      0,447{" "}
                    </P>
                    <P color="white" fontSize="12px">
                      <strong>Hidratos de Carbono:</strong> 13 g, dos quais
                      açúcares (da planta) 2,88
                    </P>
                    <P color="white" fontSize="12px">
                      <strong>Proteínas:</strong> 10,05 g
                    </P>
                    <P color="white" fontSize="12px">
                      <strong>Sal:</strong> 28,6 g
                    </P>
                  </Container>
                </Container>
              </Container>
            </Container>
          </Container>
          <Container
            width="100%"
            flex
            flexDirection="column"
            justifyContent="space-between"
            alignItems="center"
            margin="5rem 0 0"
          >
            <Container
              flex
              flexWrap="wrap"
              justifyContent="center"
              laptopJustifyContent="space-between"
              width="100%"
            >
              <Container
                justifyContent="center"
                laptopJustifyContent="space-between"
              >
                <Container
                  padding="1rem 0"
                  flex
                  flexDirection="column"
                  laptopFlexDirection="row"
                  flexWrap="wrap"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Container
                    flex
                    flexDirection="column"
                    margin="5% 0 3rem 0"
                    width="100%"
                    tabletWidth="380px"
                  >
                    <Container tabletPadding="1rem">
                      <Container
                        backgroundColor="#ffffffba"
                        borderRadius="0.5rem"
                        boxShadow="0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)"
                        laptopMinHeight="305px"
                        laptopLMinHeight="755px"
                      >
                        <Container
                          flex
                          flexDirection="column"
                          justifyContent="center"
                          textAlign="center"
                          height="180px"
                          backgroundColor="#ecab4a"
                          width="100%"
                        >
                          <Heading
                            textColor="white"
                            fontWeight="800"
                            level="1"
                            fontFamily="secondary"
                            margin="1rem 0 0"
                            textAlign="center"
                          >
                            Espargos Grelhados Com Ovos Estrelados
                          </Heading>
                        </Container>
                        <Container
                          backgroundImage="/receitas_imgs/receitasverde.png"
                          margin="0 0 2% 0"
                          height="150px"
                          tabletHeight="200px"
                          backgroundSize="cover"
                          backgroundRepeat="no-repeat"
                          backgroundPosition="center"
                        ></Container>
                        <Container
                          width="100%"
                          flex
                          justifyContent="flex-end"
                        ></Container>
                        <Container padding="2rem 2rem 1rem">
                          <Container margin="1rem 0 0">
                            <P fontSize="15px" fontWeight="700" color="#70a060">
                              INGREDIENTES PARA 4 PESSOAS:
                            </P>
                            <P fontSize="15px" color="#70a060">
                              250 gramas de espargos (62,5g por pessoa)
                            </P>
                            <P fontSize="15px" color="#70a060">
                              4ovos
                            </P>
                            <br></br>
                            <P fontSize="15px" fontWeight="700" color="#70a060">
                              PREPARAÇÃO:
                            </P>
                            <P fontSize="15px" color="#70a060">
                              Começamos por grelhar os espargos e reservamos. De
                              seguida, estrelamos os ovos na mesma frigideira
                              dos espargos com um fio de azeite até ficarem no
                              nosso ponto preferido.
                            </P>
                            <P fontSize="15px" color="#70a060">
                              Temperamos com uma pitada de Salicórnia 100% para
                              dar o toque final e perfeito de sabor.
                            </P>
                            <P fontSize="15px" color="#70a060">
                              Podem-se servir sobre umas fatias torradas de pão
                              para um pequeno-almoço cheio de energia e sem usar
                              sal.
                            </P>
                          </Container>
                        </Container>
                      </Container>
                    </Container>
                  </Container>
                </Container>
              </Container>
              <Container flex justifyContent="center">
                <Container
                  padding="1rem 0"
                  flex
                  flexDirection="column"
                  laptopFlexDirection="row"
                  flexWrap="wrap"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                >
                  <Container
                    flex
                    flexDirection="column"
                    margin="5% 0 3rem 0"
                    width="100%"
                    tabletWidth="380px"
                  >
                    <Container tabletPadding="1rem">
                      <Container
                        backgroundColor="#ffffffba"
                        borderRadius="0.5rem"
                        boxShadow="0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)"
                        laptopMinHeight="405px"
                        laptopLMinHeight="400px"
                      >
                        <Container
                          flex
                          flexDirection="column"
                          justifyContent="center"
                          textAlign="center"
                          height="180px"
                          backgroundColor="#ecab4a"
                          width="100%"
                        >
                          <Heading
                            textColor="white"
                            fontWeight="800"
                            level="1"
                            fontFamily="secondary"
                            margin="1rem 0 0"
                            textAlign="center"
                          >
                            Esparguete com camarões salteados
                          </Heading>
                        </Container>
                        <Container
                          backgroundImage="/receitas_imgs/receitasamarelo.png"
                          margin="0 0 2% 0"
                          height="150px"
                          tabletHeight="200px"
                          backgroundSize="cover"
                          backgroundRepeat="no-repeat"
                          backgroundPosition="center"
                        ></Container>
                        <Container
                          width="100%"
                          flex
                          justifyContent="flex-end"
                        ></Container>
                        <Container padding="2rem 2rem 1rem">
                          <Container margin="1rem 0 0">
                            <Container margin="1rem 0 0">
                              <P
                                fontSize="15px"
                                fontWeight="700"
                                color="#70a060"
                              >
                                INGREDIENTES PARA 4 PESSOAS:
                              </P>
                              <P fontSize="15px" color="#70a060">
                                500 gramas de esparguete
                              </P>
                              <P fontSize="15px" color="#70a060">
                                650 gramas de camarões descascados
                              </P>
                              <P fontSize="15px" color="#70a060">
                                1 limão
                              </P>
                              <P fontSize="15px" color="#70a060">
                                Mix Salicórnia Massas
                              </P>
                              <br></br>
                              <P
                                fontSize="15px"
                                fontWeight="700"
                                color="#70a060"
                              >
                                PREPARAÇÃO:
                              </P>
                              <P fontSize="15px" color="#70a060">
                                Começamos por cozinhar o esparguete, respeitando
                                o tempo na embalagem com um fio generoso de
                                azeite e sem usar sal na água. Assim que estiver
                                pronto, escorremos e reservamos o esparguete.
                              </P>
                              <P fontSize="15px" color="#70a060">
                                Numa frigideira já quente adicionamos um fio de
                                azeite, colocamos os camarões e o tempero Mix
                                Salicórnia Massas (1 colher de chá bem cheia por
                                pessoa). Envolvemo tudo muito bem até os
                                camarões estarem no ponto e sem deixar que
                                cozinhem demasiado para não se tornarem rijos.É
                                então que adicionamos a massa já escorrida à
                                frigideira e salteamos até estar tudo bem
                                envolvido.
                              </P>
                              <P fontSize="15px" color="#70a060">
                                Terminamos com um pouco de sumo de limão, para
                                potenciar ainda mais o sabor desta deliciosa
                                combinação.E está pronto a servir sem usar uma
                                pitada de sal.
                              </P>
                            </Container>
                          </Container>
                        </Container>
                      </Container>
                    </Container>
                  </Container>
                </Container>
              </Container>
              <Container flex justifyContent="center">
                <Container
                  padding="1rem 0"
                  flex
                  flexDirection="column"
                  laptopFlexDirection="row"
                  flexWrap="wrap"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Container
                    flex
                    flexDirection="column"
                    margin="5% 0 3rem 0"
                    width="100%"
                    tabletWidth="380px"
                  >
                    <Container tabletPadding="1rem">
                      <Container
                        backgroundColor="#ffffffba"
                        borderRadius="0.5rem"
                        boxShadow="0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)"
                        laptopMinHeight="405px"
                        laptopLMinHeight="400px"
                      >
                        <Container
                          flex
                          flexDirection="column"
                          justifyContent="center"
                          textAlign="center"
                          height="180px"
                          backgroundColor="#ecab4a"
                          width="100%"
                        >
                          <Heading
                            textColor="white"
                            fontWeight="800"
                            level="1"
                            fontFamily="secondary"
                            margin="1rem 0 0"
                            textAlign="center"
                          >
                            Salmão ao vapor em Papelote
                          </Heading>
                        </Container>
                        <Container
                          backgroundImage="/receitas_imgs/receitasazul.png"
                          margin="0 0 2% 0"
                          height="150px"
                          tabletHeight="200px"
                          backgroundSize="cover"
                          backgroundRepeat="no-repeat"
                          backgroundPosition="bottom"
                        ></Container>
                        <Container
                          width="100%"
                          flex
                          justifyContent="flex-end"
                        ></Container>
                        <Container padding="2rem 2rem 1rem">
                          <Container margin="1rem 0 0">
                            <Container margin="1rem 0 0">
                              <P
                                fontSize="15px"
                                fontWeight="700"
                                color="#70a060"
                              >
                                INGREDIENTES PARA 4 PESSOAS:
                              </P>
                              <P fontSize="15px" color="#70a060">
                                500 gramas de esparguete
                              </P>
                              <P fontSize="15px" color="#70a060">
                                650 gramas de camarões descascados
                              </P>
                              <P fontSize="15px" color="#70a060">
                                1 limão
                              </P>
                              <P fontSize="15px" color="#70a060">
                                Mix Salicórnia Massas
                              </P>
                              <br></br>
                              <P
                                fontSize="15px"
                                fontWeight="700"
                                color="#70a060"
                              >
                                PREPARAÇÃO:
                              </P>
                              <P fontSize="15px" color="#70a060">
                                Começamos por cozinhar o esparguete, respeitando
                                o tempo na embalagem com um fio generoso de
                                azeite e sem usar sal na água. Assim que estiver
                                pronto, escorremos e reservamos o esparguete.
                              </P>
                              <P fontSize="15px" color="#70a060">
                                Numa frigideira já quente adicionamos um fio de
                                azeite, colocamos os camarões e o tempero Mix
                                Salicórnia Massas (1 colher de chá bem cheia por
                                pessoa). Envolvemo tudo muito bem até os
                                camarões estarem no ponto e sem deixar que
                                cozinhem demasiado para não se tornarem rijos.É
                                então que adicionamos a massa já escorrida à
                                frigideira e salteamos até estar tudo bem
                                envolvido.
                              </P>
                              <P fontSize="15px" color="#70a060">
                                Terminamos com um pouco de sumo de limão, para
                                potenciar ainda mais o sabor desta deliciosa
                                combinação.E está pronto a servir sem usar uma
                                pitada de sal.
                              </P>
                            </Container>
                          </Container>
                        </Container>
                      </Container>
                    </Container>
                  </Container>
                </Container>
              </Container>
            </Container>
          </Container>
        </Container>
      </Container>
    </Container>
  );
};

export default NovaNewsletter;
