//Import React Components
import React from "react";

//Import Data
import productItems from "./productItems";

//Import Component
import Container from "../../../components/Container";
import Heading from "../../../components/Heading";

const Products = () => {
  return (
    <Container position="relative">
      {/* Background Image */}
      <Container
        position="absolute"
        width="100%"
        height="100%"
        top="0"
        laptopTop="60%"
        backgroundImage="/fundos_pintas/pintas_pretas.svg"
        left="0"
        bottom="0"
        zIndex="-1"
      ></Container>
      <Container
        flex
        tabletFlexWrap="wrap"
        flexWrap="nowrap"
        laptopFlexDirection="row"
        tabletFlexDirection="row"
        flexDirection="column"
        laptopPadding="0rem 5rem"
        laptopLPadding="3rem 12.5rem"
        padding="3rem"
        maxWidth="1000px"
        margin="0 auto"
      >
        {productItems.map((item, index) => {
          return (
            <Container
              margin="3rem 0"
              laptopWidth="25%"
              laptopLWidth="25%"
              width="100%"
              flex
              tabletWidth="50%"
              flexDirection="column"
              className="Display_card"
              key={index}
            >
              <Container position="relative" width="150%" right="66%">
                <Container>
                  <img width="100%" src={item.img} alt="salicornia peixe" />
                </Container>
              </Container>
              <Container
                backgroundImage={item.bgImg}
                backgroundSize="cover"
                height="200px"
                laptopHeight="200px"
                desktopHeight="250px"
                backgroundRepeat="no-repeat"
                backgroundPosition="center bottom"
                margin="-50px 0 0"
                laptopMargin="-50px 0 0"
              >
                <Container
                  flex
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  height="100%"
                  width="100%"
                >
                  {item.headingTop ? (
                    <Heading
                      width="fit-content"
                      textColor="white"
                      fontWeight="800"
                      level="1.5"
                      laptopLevel="3"
                      fontFamily="secondary"
                    >
                      {item.headingTop}
                    </Heading>
                  ) : (
                    ""
                  )}
                  <Heading
                    width="fit-content"
                    textColor="white"
                    fontWeight="800"
                    level="1"
                    laptopLevel="1.8"
                    fontFamily="secondary"
                  >
                    {item.headingMain}
                  </Heading>
                  <Heading
                    width="fit-content"
                    textColor="white"
                    fontWeight="800"
                    level="1.5"
                    laptopLevel="2.5"
                    fontFamily="secondary"
                  >
                    {item.headingBottom}
                  </Heading>
                </Container>
              </Container>
              <Container width="90%" margin="1rem auto 0">
                <img width="100%" display="block" src={item.ingredients} />
              </Container>
            </Container>
          );
        })}
      </Container>
    </Container>
  );
};

export default Products;
