import styled from "styled-components";

import { device } from "../../util/Theme";

const Wrapper = styled.a`
  text-align: ${({ textAlign }) => textAlign};
  font-size: ${({ fontSize }) => fontSize};
  margin: ${({ margin }) => margin};
  text-decoration: ${({ textDecoration }) => textDecoration};
  color: ${({ color }) => color};
  font-weight: ${({ fontWeight }) => fontWeight};

  @media ${device.laptop} {
    font-size: ${({ laptopFontSize }) => laptopFontSize};
  }

  @media ${device.laptopL} {
    font-size: ${({ laptopLFontSize }) => laptopLFontSize};
  }
`;

export default Wrapper;
