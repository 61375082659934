//Import React Components
import React, { useState, useEffect } from "react";

// Import Utils
import FacebookIcon from "../../util/SVG/Socials/facebook";
import InstagramIcon from "../../util/SVG/Socials/instagram";
import { Squash as Hamburger } from "hamburger-react";

//Import Data
import menuItems from "./menuitems";

//Import Component
import A from "../../components/A";
import P from "../../components/P";
import Container from "../../components/Container";
import SVG from "../../components/SVG";

const Navbar = () => {
  const [toggle, setToggle] = useState(false);
  const [hamburger, setHamburger] = useState("fixed");
  const [widthScroll, setWidthScroll] = useState(0);

  const fixed = "fixed";

  function SmoothScroll(argument, isHamburger) {
    toggleMenu(isHamburger);
    let sectionPosition = document.getElementById(argument).offsetTop;
    let sectionPositionWithoutNavHeight = sectionPosition - 140;
    window.scrollTo({
      top: sectionPositionWithoutNavHeight,
      behavior: "smooth",
    });
  }

  function toggleMenu(argument) {
    setToggle(!toggle);
    if (toggle === false && argument === "fixed") {
      document.body.style.overflowY = "hidden";
    } else if (argument === "fixed" && toggle === true) {
      document.body.style.overflowY = "scroll";
    }
  }
  function handleScroll() {
    let position = window.scrollY;
    let height = window.innerHeight;
    let bottom = document.body.scrollHeight;
    setWidthScroll((position * 100) / (bottom - height));
  }
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  });

  function goTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  return (
    <>
      <Container
        id="nav"
        width="100%"
        position="fixed"
        backgroundColor="white"
        zIndex="40000"
        textAlign="center"
      >
        <Container
          padding="0 1rem"
          tabletPadding="0 3rem"
          laptopPadding="0 2rem"
          laptopLPadding="0 3rem"
          maxWidth="1400px"
          margin="0 auto"
          width="100%"
        >
          <Container
            flex
            justifyContent="space-between"
            laptopJustifyContent="space-around"
            alignItems="center"
          >
            <Container
              flex
              tabletWidth="10%"
              padding="0.5rem 0"
              height={widthScroll > 0 ? "60px" : "80px"}
              tabletHeight="100px"
              justifyContent="flex-start"
              tabletJustifyContent="center"
              transition="height 1s"
              cursor="pointer"
              onClick={goTop}
            >
              <img
                height="100%"
                src="/page_elements/logo-salicornia.png"
                alt="logo-salicornia"
              />
            </Container>
            <Container
              display="none"
              laptopDisplay="flex"
              flex
              justifyContent="center"
              alignItems="center"
            >
              {menuItems.map((item, index) => {
                return (
                  <Container key={index} margin="0 10px">
                    <P
                      key={item.name}
                      fontSize="12px"
                      laptopLFontSize="13px"
                      color="#164427"
                      className="navbar-links"
                      textDecoration="none"
                      onClick={() => SmoothScroll(item.id)}
                      style={{ cursor: "pointer" }}
                    >
                      {item.name.toUpperCase()}
                    </P>
                  </Container>
                );
              })}
            </Container>
            <Container
              className="social_section"
              flex
              justifyContent="center"
              alignItems="center"
              display="none"
              laptopDisplay="flex"
              laptopLPadding="0 2rem"
              laptopPadding="0 3rem 0 1rem"
            >
              <A
                textDecoration="none"
                href="https://www.facebook.com/Salicornia_qampo-105658228550810"
                target="_blank"
              >
                <SVG
                  flex
                  justifyContent="center"
                  alignItems="center"
                  margin="5px"
                  width="30"
                  height="30"
                  fill="white"
                  stroke="#304633"
                  background="#71A060"
                  borderRadius="50%"
                  svg={<FacebookIcon />}
                />
              </A>
              <A
                href="https://www.instagram.com/salicornia_qampo/"
                target="_blank"
              >
                <SVG
                  flex
                  justifyContent="center"
                  alignItems="center"
                  margin="5px"
                  width="30"
                  height="30"
                  stroke="#304633"
                  fill="white"
                  background="#71A060"
                  borderRadius="50%"
                  svg={<InstagramIcon />}
                />
              </A>
            </Container>
            <Container
              onClick={() => toggleMenu(fixed)}
              display="block"
              zIndex="3002"
              laptopDisplay="none"
              padding="0 2rem 0"
              tabletPadding="0 4rem 0 "
            >
              <Hamburger toggled={toggle} color="#164427" />
            </Container>
          </Container>
        </Container>
        <Container
          right={toggle ? "0" : "-160%"}
          height="100vh"
          width="100vw"
          backgroundColor="#71A060"
          padding="1rem 0"
          position="absolute"
          top="0"
          laptopDisplay="none"
          transition="all 1s ease 0s"
          zIndex="3000"
          flex
          alignItems="center"
        >
          <Container
            display="flex"
            flexDirection="column"
            justifyContent="space-around"
            alignItems="center"
            height="50%"
            width="100%"
          >
            {menuItems.map((item, index) => {
              return (
                <P
                  key={index}
                  fontSize="0.5"
                  color="whitesmoke"
                  fontWeight="800"
                  className="navbar-links"
                  textDecoration="none"
                  onClick={() => SmoothScroll(item.id, fixed)}
                  style={{ cursor: "pointer" }}
                >
                  {item.name.toUpperCase()}
                </P>
              );
            })}
          </Container>
        </Container>
        <span
          style={{
            borderTop: "3px solid transparent",
            borderImage:
              "linear-gradient(90deg, rgba(113,160,96,1) 0%, rgba(139,230,107,1) 100%)",
            borderImageSlice: "1",
            display: "block",
            position: "relative",
            width: `${widthScroll}%`,
          }}
        ></span>
      </Container>
    </>
  );
};

export default Navbar;
