import * as React from "react";

function Processamento(props) {
  return (
    <svg
      id="qamposection-svg"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 200 200"
      xmlSpace="preserve"
      {...props}
      style={{backgroundColor:"rgb(217 62 55)"}}
    >
      <style>{".prefix__st0{fill:#729b5e}"}</style>
      <path
        className="prefix__st0"
        d="M169.75 84.65c-.48 0-.96-.13-1.39-.4L101.82 42.9a2.643 2.643 0 012.79-4.49l66.54 41.35a2.643 2.643 0 01-1.4 4.89z"
      />
      <path
        className="prefix__st0"
        d="M36.69 84.65a2.643 2.643 0 01-1.4-4.89l66.53-41.35a2.643 2.643 0 012.79 4.49L38.08 84.25c-.43.27-.91.4-1.39.4z"
      />
      <path
        className="prefix__st0"
        d="M159.27 157.73H47.18c-1.46 0-2.65-1.18-2.65-2.65V76.91c0-1.46 1.18-2.65 2.65-2.65 1.46 0 2.65 1.18 2.65 2.65v75.53h106.8V76.91c0-1.46 1.18-2.65 2.65-2.65 1.46 0 2.65 1.18 2.65 2.65v78.17c-.02 1.47-1.2 2.65-2.66 2.65z"
      />
      <path
        className="prefix__st0"
        d="M105.02 142.38a2.644 2.644 0 01-1.41-4.89c1.06-.99 2.77-7.61.01-14.04-5.53-12.88-3.01-20.86-1.81-23.5-2.9-24.28 19.88-28.05 20.11-28.09 1.37-.2 2.66.67 2.98 2.01.13.55 3.09 13.66-3.67 22.22-3.35 4.24-8.38 6.56-14.97 6.92-.84 2.39-2.02 8.46 2.23 18.34 3.57 8.31 1.82 18.3-2.11 20.65-.43.26-.9.38-1.36.38zm-1.36-4.92l-.01.01c0-.01.01-.01.01-.01zm16.51-59.72c-4.7 1.54-14.1 6.33-13.25 19.91 4.53-.42 7.94-2.04 10.15-4.83 3.62-4.57 3.51-11.36 3.1-15.08z"
      />
      <path
        className="prefix__st0"
        d="M94.61 122.31c-2.64 0-5.14-.57-7.48-1.71-9.81-4.77-12.84-17.86-12.96-18.42-.3-1.35.49-2.7 1.81-3.11.23-.07 22.89-6.72 30.59 17.36.4 1.26-.19 2.62-1.38 3.19-3.74 1.8-7.27 2.69-10.58 2.69zm-14.49-18.69c1.25 3.53 4.09 9.7 9.34 12.24 3.2 1.55 6.98 1.53 11.24-.06-5.13-12.61-15.67-12.84-20.58-12.18zM133.78 157.73c-.08 0-.16 0-.25-.01a2.634 2.634 0 01-2.39-2.88c.4-4.35-3.94-6.84-5.83-7.71-4.25-1.96-8.44-1.69-9.46-.65-.6.62-1.46.9-2.32.77-.85-.14-1.58-.68-1.96-1.45-.85-1.74-3.87-3.14-7.35-3.4-3.37-.26-7.76.6-9.92 3.74a2.648 2.648 0 01-3.29.9c-2.66-1.23-7.2-.54-10.56 1.61-1.53.98-4.1 3.12-4.09 6.43 0 1.46-1.18 2.65-2.64 2.65-1.46 0-2.64-1.18-2.65-2.64-.01-4.33 2.31-8.2 6.53-10.9 4.2-2.69 9.52-3.66 13.66-2.64 3.09-3.2 7.94-4.83 13.37-4.42 4.18.31 7.77 1.79 10 4.01 3.66-1.44 8.84-.67 12.9 1.2 6.06 2.79 9.38 7.65 8.88 13a2.647 2.647 0 01-2.63 2.39z"
      />
    </svg>
  );
}

export default Processamento;
