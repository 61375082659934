import * as React from "react";

function Secagem(props) {
  return (
    <svg
      id="qamposection-svg"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 200 200"
      xmlSpace="preserve"
      {...props}
      style={{backgroundColor:"rgb(240, 175, 98)"}}
    >
      <style>{".prefix__st0{fill:#729b5e}"}</style>
      <path
        className="prefix__st0"
        d="M110.94 100.65c-3.01 0-5.47-2.45-5.47-5.47 0-3.01 2.45-5.47 5.47-5.47 3.01 0 5.47 2.45 5.47 5.47 0 3.02-2.45 5.47-5.47 5.47zm0-5.64c-.09 0-.17.08-.17.17 0 .19.34.19.35 0 0-.09-.08-.17-.18-.17z"
      />
      <path
        className="prefix__st0"
        d="M110.94 155.32c-1.46 0-2.65-1.18-2.65-2.65 0-1.46 1.18-2.65 2.65-2.65 30.24 0 54.84-24.6 54.84-54.84 0-30.24-24.6-54.84-54.84-54.84-13.82 0-27.02 5.15-37.17 14.51-10.08 9.32-16.29 21.95-17.46 35.57a2.626 2.626 0 01-2.86 2.41 2.65 2.65 0 01-2.41-2.86c1.29-14.94 8.09-28.79 19.15-38.99a59.964 59.964 0 0140.75-15.92c33.16 0 60.13 26.97 60.13 60.13.01 33.15-26.97 60.13-60.13 60.13z"
      />
      <path
        className="prefix__st0"
        d="M146.08 97.83h-32.31c-1.46 0-2.65-1.18-2.65-2.65s1.18-2.65 2.65-2.65h32.31c1.46 0 2.65 1.18 2.65 2.65s-1.19 2.65-2.65 2.65z"
      />
      <path
        className="prefix__st0"
        d="M110.94 95.01c-1.46 0-2.65-1.19-2.65-2.65V57.43c0-1.46 1.18-2.65 2.65-2.65s2.65 1.19 2.65 2.65v34.94a2.64 2.64 0 01-2.65 2.64zM75.59 139.68c-.06 0-.12 0-.18-.01a2.644 2.644 0 01-2.46-2.82c.21-3.03-.2-5.88-1.13-8.04-7.42-17.06-4.39-27.82-3.25-30.81-1.33-10.13.58-18.43 5.69-24.69 8.22-10.07 21.64-10.95 22.21-10.99 1.29-.08 2.43.78 2.73 2.03.16.68 3.86 16.85-4.78 27.62-4.62 5.76-11.77 8.79-21.26 9.02-.94 3.3-2.37 12.19 3.52 25.71 1.63 3.75 1.75 7.79 1.56 10.51-.1 1.4-1.27 2.47-2.65 2.47zm18.85-71.76c-3.76.68-11.23 2.75-16.11 8.76-3.86 4.75-5.44 11.14-4.7 18.99 7.54-.3 13.13-2.65 16.64-7 5.31-6.59 4.77-16.31 4.17-20.75z"
      />
      <path
        className="prefix__st0"
        d="M60 124.5c-3.03 0-5.9-.65-8.58-1.96-11.33-5.51-14.84-20.68-14.98-21.32-.3-1.35.48-2.7 1.81-3.11.26-.08 26.45-7.77 35.35 20.1.4 1.26-.19 2.62-1.38 3.19-4.31 2.07-8.4 3.1-12.22 3.1zm-17.64-21.87c1.38 4.09 4.81 11.98 11.39 15.17 3.99 1.93 8.7 1.86 14.01-.22-6.27-16.05-19.73-15.84-25.4-14.95zM101.84 155.32h-53.6c-.73 0-1.42-.3-1.92-.83s-.76-1.24-.72-1.96c.33-6.03 3.79-10.89 9.24-12.98 3.2-1.23 6.49-1.26 9.07-.18 2.73-3.31 6.85-5.05 11.49-4.76 3.81.24 7.17 1.84 9.33 4.29 1.97-1.02 4.58-1.31 7.43-.74 5.29 1.06 11.69 5.45 12.32 14.33.05.73-.2 1.45-.7 1.99-.5.53-1.2.84-1.94.84zm-50.44-5.29h47.27c-1.29-4.02-4.56-6.09-7.55-6.69-2.59-.52-4.27.18-4.6.82a2.65 2.65 0 01-2.51 1.45 2.631 2.631 0 01-2.33-1.72c-.81-2.16-3.53-3.81-6.62-4.01-3.5-.22-6.5 1.39-8.05 4.32-.39.74-1.11 1.26-1.94 1.38-.83.13-1.67-.15-2.27-.74-.97-.97-3.37-1.4-6.07-.36-1.34.52-4.16 2-5.33 5.55z"
      />
    </svg>
  );
}

export default Secagem;
