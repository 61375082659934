import styled from "styled-components";

import { device, theme } from "../../util/Theme";

const Wrapper = styled.p`
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  margin: ${({ margin }) => margin};
  color: ${({ color }) => color};
  line-height: ${({ lineHeight }) => lineHeight};
  text-align: ${({ textAlign }) => textAlign};
  letter-spacing: ${({ letterSpacing }) => letterSpacing};
  font-family: ${({ fontFamily }) =>
    fontFamily === "secondary"
      ? theme.main.fonts.secondary
      : theme.main.fonts.primary};

  @media ${device.tablet} {
    width: ${({ tabletWidth }) => tabletWidth};
    font-size: ${({ tabletFontSize }) => tabletFontSize};
  }

  @media ${device.laptop} {
    font-size: ${({ laptopFontSize }) => laptopFontSize};
    text-align: ${({ laptopTextAlign }) => laptopTextAlign};
  }

  @media ${device.laptopL} {
    font-size: ${({ laptopLFontSize }) => laptopLFontSize};
  }
`;

export default Wrapper;
