import React from "react";

//-------------------
// Import Sections
//-------------------
import Hero from "./Hero";
import SobreSalicornia from "./Sobre";
import InfoNutricional from "./InfoNutricional";
import IntroProducts from "./Products/intro";
import Products from "./Products/display";
import Navbar from "../../Containers/Navbar";
import ReceitasIntro from "./Receitas/intro";
import Recipes from "./Receitas/display";
import ClippingSlider from "./ClippingSlider";
import OndeComprar from "./OndeComprar";
import Email from "./email";

import BacktoTop from "../../Containers/BacktoTop";

const Home = () => {
  return (
    <>
      <Navbar />
      <Hero />
      <SobreSalicornia />
      <InfoNutricional />
      <IntroProducts />
      <Products />
      <ReceitasIntro />
      <Recipes />
      <ClippingSlider />
      <OndeComprar />
      <Email />
      <BacktoTop />
    </>
  );
};

export default Home;
