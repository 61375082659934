const productItems = [
  {
    img: "/produtos/salicornia_peixe.png",
    headingTop: "MIX",
    headingMain: "SALICÓRNIA",
    headingBottom: "PEIXE",
    bgImg: "/fundo_rasgado/azul_rasgado.png",
    ingredients: "/ingredientes/ingredientes_peixe.png",
  },
  {
    img: "/produtos/salicornia_massas.png",
    headingTop: "MIX",
    headingMain: "SALICÓRNIA",
    headingBottom: "MASSAS",
    bgImg: "/fundo_rasgado/amarelo_rasgado.png",
    ingredients: "/ingredientes/ingredientes_massas.png",
  },
  {
    img: "/produtos/salicornia_grelhados.png",
    headingTop: "MIX",
    headingMain: "SALICÓRNIA",
    headingBottom: "CARNE",
    bgImg: "/fundo_rasgado/vermelho_rasgado.png",
    ingredients: "/ingredientes/ingredientes_grelhados.png",
  },
  {
    img: "/produtos/salicornia_saladas.png",
    headingTop: "",
    headingMain: "SALICÓRNIA",
    headingBottom: "100%",
    bgImg: "/fundo_rasgado/verde_rasgado.png",
    ingredients: "/ingredientes/ingredientes_saladas.png",
  },
];

export default productItems;
