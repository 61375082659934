import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Footer from "./Containers/Footer";
import Container from "./components/Container";

import Home from "./Pages/Home";
import NewsletterAbril from "./Pages/NewsletterAbril";
import NewsletterMaio from "./Pages/NewsletterMaio";
import NovaNewsletter from "./Pages/NovaNewsletter";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function App() {
  return (
    <Container>
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route
            exact
            path="/newsletterabrilsalicorniadesidratadacortasal"
            element={<NewsletterAbril />}
          />
          <Route
            exact
            path="/newslettermaiovamoscortarosalpelaraiz"
            element={<NewsletterMaio />}
          />
          <Route
            exact
            path="/newslettercomodiminuiroueliminaraingestaodesal"
            element={<NovaNewsletter />}
          />
        </Routes>
      </Router>
      <Footer />
    </Container>
  );
}

export default App;
