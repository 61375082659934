// Import Components
import Container from "../../../components/Container";
import Heading from "../../../components/Heading";
import P from "../../../components/P";

const InfoNutricional = () => {
  return (
    <Container
      id="informacao-nutricional"
      backgroundImage="/fundos_pintas/pintas_pretas.svg"
      backgroundSize="auto"
      borderBottom="2px dashed #164427"
    >
      <Container
        laptopPadding="6rem 5rem"
        laptopLPadding="6rem 12.5rem"
        padding="3rem"
        maxWidth="1000px"
        margin="0 auto"
        position="relative"
      >
        <Container
          laptopLWidth="78%"
          laptopWidth="65%"
          tabletWidth="70%"
          width="100%"
          margin="0 0 3rem 0"
        >
          <Container>
            <P fontSize="15px" laptopFontSize="18px" color="#164427">
              Rica em vitaminas A, C e D e sais minerais, a Salicórnia tem
              propriedade antioxidante e anti-inflamatória, diurética e
              antidiabética e a sua utilização permite reduzir a hipertensão
              arterial. Com um teor de sódio 75% mais baixo comparado ao Sal
              comum, a Salicórnia também confere o sabor salgado. 5 gramas de
              Salicórnia equivalem a um teor de sódio de 50 mg.
            </P>
          </Container>
        </Container>
        <Container
          position="absolute"
          top="-30%"
          right="-12.5rem"
          width="70%"
          laptopLWidth="80%"
          maxWidth="800px"
          zIndex="9999"
          display="none"
          tabletDisplay="block"
        >
          <img width="100%" src="/page_elements/iStock-4.png" />
        </Container>
        <Container>
          <Container
            width="100%"
            flex
            margin="0 0 3rem"
            alignItems="center"
            justifyContent="space-between"
            tabletJustifyContent="flex-start"
          >
            <Container width="60%" laptopWidth="60%">
              <Heading
                textColor="#304633"
                fontWeight="800"
                level="1.5"
                laptopLevel="2"
                margin="0"
                fontFamily="secondary"
              >
                INFORMAÇÃO NUTRICIONAL
              </Heading>
            </Container>
            <Container width="40%" laptopWidth="50%" flex>
              <Heading
                fontWeight="800"
                level="1.5"
                laptopLevel="3"
                textColor="#304633"
                display="flex"
                justifyContent="flex-end"
                mobileLjustifyContent="flex-start"
                fontFamily="secondary"
              >
                (100g)
              </Heading>
            </Container>
          </Container>
          <Container
            flex
            flexDirection="column"
            width="100%"
            tabletWidth="40%"
            laptopWidth="60%"
            laptopLWidth="40%"
          >
            <Container
              flex
              justifyContent="space-between"
              width="100%"
              borderBottom="1px solid rgba(48,70,51,0.4)"
              margin="1% 0 0 0"
            >
              <P
                margin="0"
                color="#304633"
                fontSize="12px"
                laptopFontSize="20px"
                fontWeight="800"
              >
                ENERGIA
              </P>
              <P
                margin="0"
                color="#304633"
                fontSize="12px"
                laptopFontSize="20px"
                fontWeight="800"
              >
                680 kJ/ 163 kcal
              </P>
            </Container>
            <Container
              flex
              justifyContent="space-between"
              width="100%"
              borderBottom="1px solid rgba(48,70,51,0.4)"
              margin="1% 0 0 0"
            >
              <P
                margin="0"
                color="#304633"
                fontSize="12px"
                laptopFontSize="20px"
                fontWeight="800"
              >
                LÍPIDOS
              </P>
              <P
                margin="0"
                color="#304633"
                fontSize="12px"
                laptopFontSize="20px"
                fontWeight="800"
              >
                0,968 g
              </P>
            </Container>
            <Container
              flex
              justifyContent="space-between"
              width="100%"
              borderBottom="1px solid rgba(48,70,51,0.4)"
              margin="1% 0 0 0"
            >
              <P
                margin="0"
                color="#304633"
                fontSize="12px"
                laptopFontSize="20px"
                fontWeight="800"
              >
                dos quais saturados
              </P>
              <P
                margin="0"
                color="#304633"
                fontSize="12px"
                laptopFontSize="20px"
                fontWeight="800"
              >
                0,447 g
              </P>
            </Container>
            <Container
              flex
              justifyContent="space-between"
              width="100%"
              borderBottom="1px solid rgba(48,70,51,0.4)"
              margin="1% 0 0 0"
            >
              <P
                margin="0"
                color="#304633"
                fontSize="12px"
                laptopFontSize="20px"
                fontWeight="800"
              >
                HIDRATOS DE CARBONO
              </P>
              <P
                margin="0"
                color="#304633"
                fontSize="12px"
                laptopFontSize="20px"
                fontWeight="800"
              >
                13 g
              </P>
            </Container>
            <Container
              flex
              justifyContent="space-between"
              width="100%"
              borderBottom="1px solid rgba(48,70,51,0.4)"
              margin="1% 0 0 0"
            >
              <P
                margin="0"
                color="#304633"
                fontSize="12px"
                laptopFontSize="20px"
                fontWeight="800"
              >
                dos quais açúcares
              </P>
              <P
                margin="0"
                color="#304633"
                fontSize="12px"
                laptopFontSize="20px"
                fontWeight="800"
              >
                2,88 g
              </P>
            </Container>
            <Container
              flex
              justifyContent="space-between"
              width="100%"
              borderBottom="1px solid rgba(48,70,51,0.4)"
              margin="1% 0 0 0"
            >
              <P
                margin="0"
                color="#304633"
                fontSize="12px"
                laptopFontSize="20px"
                fontWeight="800"
              >
                PROTEÍNAS
              </P>
              <P
                margin="0"
                color="#304633"
                fontSize="12px"
                laptopFontSize="20px"
                fontWeight="800"
              >
                10,05 g
              </P>
            </Container>
            <Container
              flex
              justifyContent="space-between"
              width="100%"
              margin="1% 0 0 0"
            >
              <P
                margin="0"
                color="#304633"
                fontSize="12px"
                laptopFontSize="20px"
                fontWeight="800"
              >
                SAL
              </P>
              <P
                margin="0"
                color="#304633"
                fontSize="12px"
                laptopFontSize="20px"
                fontWeight="800"
              >
                28,6 g
              </P>
            </Container>
          </Container>
        </Container>
      </Container>
    </Container>
  );
};

export default InfoNutricional;
